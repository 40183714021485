import Vue from "vue";
import VueRouter from "vue-router";

import PublicLayout from "@/views/Layout/PublicLayout.vue";

import PageLayout from "@/views/Layout/PageLayout.vue";
import PageSubLayout from "@/views/Layout/PageSubLayout.vue";
import DetailsLayout from "@/views/Layout/DetailsLayout.vue";


const Home = () => import("../views/Pages/index/Index.vue");
const Movies = () => import("@/views/Pages/movies/Index.vue");
const MoviesList = () => import("@/views/Pages/movies/List.vue");
const MoviesSingle = () => import("@/views/Pages/movies/Single.vue");

const tvShows = () => import("@/views/Pages/tvShows/Index.vue");
const tvShowsList = () => import("@/views/Pages/tvShows/List.vue");
const tvShowsSingle = () => import("@/views/Pages/tvShows/Single.vue");
const tvShowsSingleEpisode = () => import("@/views/Pages/tvShows/Episode.vue");

const video = () => import("@/views/Pages/video/Index.vue");
const videoList = () => import("@/views/Pages/video/List.vue");
const videoSingle = () => import("@/views/Pages/video/Single.vue");

const About = () => import("@/views/Pages/about/Index.vue");
const Contact = () => import("@/views/Pages/about/Contact.vue");

const Register =() => import("../views/Pages/login/Register.vue");

const Password =() => import("../views/Pages/login/Password.vue");



Vue.use(VueRouter);

let loginPages = {
  path: "/",
  component: PublicLayout,
  name: "Login Register",
  children: [
    {
      path: "/pages/login/register",
      name: "Register",
      component: Register,
      meta: {
        groupName: "login",
      },
    },
    {
      path: "/pages/login/password",
      name: "Password",
      component: Password,
      meta: {
        groupName: "password",
      },
    },
  ],
};

let subPage = {
  path: "/pages/movies/index",
  component: PageSubLayout,
  name: "Movies",
  children: [
    {
      path: "/pages/movies/index",
      name: "Movies",
      component: Movies,
      meta: {
        groupName: "Movies",
      },
    },
    {
      path: "/pages/movies/list",
      name: "Movies",
      component: MoviesList,
      meta: {
        groupName: "MoviesList",
      },
    },


    {
      path: "/pages/tvShows/index",
      name: "Tv Shows",
      component: tvShows,
      meta: {
        groupName: "TvShows",
      },
    },
    {
      path: "/pages/tvShows/list",
      name: "Tv Shows",
      component: tvShowsList,
      meta: {
        groupName: "TvShowsList",
      },
    },

    {
      path: "/pages/video/index",
      name: "Video",
      component: video,
      meta: {
        groupName: "Video",
      },
    },
    {
      path: "/pages/video/list",
      name: "Video",
      component: videoList,
      meta: {
        groupName: "VideoList",
      },
    },
    {
      path: "/pages/about/index",
      name: "About Us",
      component: About,
      meta: {
        groupName: "About Us",
      },
    },
    {
      path: "/pages/about/contact",
      name: "Contact Us",
      component: Contact,
      meta: {
        groupName: "Contact Us",
      },
    },

  ],
};

let detailsPage = {
  path: "/pages/movies/episode",
  component: DetailsLayout,
  name: "Movies",
  children: [
    {
      path: "/pages/movies/single",
      name: "Single",
      component: MoviesSingle,
      meta: {
        groupName: "MoviesSingle",
      },
    },
    {
      path: "/pages/tvShows/single",
      name: "Single",
      component: tvShowsSingle,
      meta: {
        groupName: "SingleTvShows",
      },
    },
    {
      path: "/pages/tvShows/episode",
      name: "Episode",
      component: tvShowsSingleEpisode,
      meta: {
        groupName: "Single Episode",
      },
    },
    {
      path: "/pages/video/single",
      name: "Single",
      component: videoSingle,
      meta: {
        groupName: "Single Video",
      },
    },

  ],
};

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/pages/index/index",
    component: PageLayout,
    children: [
      {
        path: "/pages/index/index",
        name: "Home",
        component: Home,
        meta: {
          groupName: "Dashboards",
        },
      },



    ],
  },
  loginPages,subPage,detailsPage,

];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path === from.path) {
    const error = new Error('NavigationDuplicated');
    next(error);
  } else {
    next();
  }
});
export default router;
