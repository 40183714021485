<template>
  <v-app>
    <v-main class="auth-pages">

      <app-bar-auth class="mt-5" :class="$vuetify.breakpoint.mobile?'':'shadow-lg'" background="" has-bg linkColor="white" @inLoginEvent="inLoginEvent">
      </app-bar-auth>

      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container fluid class="">

          <router-view></router-view>
          <content-footer  auth></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition,CollapseTransition  } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "page-layout",
  computed: {
    ...mapState({
      inLogin: state => state.inLogin
    }),
  },
  components: {
    AppBarAuth,
    FadeTransition,CollapseTransition ,
    ContentFooter,
  },
  data() {
    return {
      login:false,
      loginTime:500,
      form:{
        name:null,
        password:null,
      },
      showPassword: false,

      paragraphs: "",
      tab: null,
      active: true,
      // tabIndex:0,
      tabList:[{
        name: this.$t('HOME'),
        type:0,
        link:'/pages/index/index',
      },{
        name:this.$t('COMBO'),
        type:1,
        link:'/pages/movies/index',
      },{
        name:this.$t('VIP'),
        type:2,
        link:'/pages/member/index',
      },{
        name:this.$t('TRAELING'),
        type:3,
        link:'/pages/strategy/index',
      },{
        name:this.$t('ABOUT'),
        type:4,
        link:'/pages/about/index',
      },]
    };
  },
  methods: {
    onLoginEvent(){
      this.$store.commit('setInLogin', true);
      this.login = false;
    },
    inLoginEvent(){
      this.login = !this.login;
      this.loginTime = this.login?500:50;
    },
    setTab(item){
      // this.tabIndex = item.type;
      console.log(item)
      if(item.type==2 && !this.inLogin){
        this.login = true;
        this.loginTime = this.login?500:50;
      }
    },
    // 忘记密码
    goPassword(){
      console.log('goPassword')
      this.$router.push({
        path:'/pages/login/password',
      })
    },
    headerTitle() {
      switch (this.$route.name) {
        case "Pricing":
          this.paragraphs =
            "You have Free Unlimited Updates and Premium Support on each package.";
          return "Pick the best plan for you";
        case "SignUpBasic":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Welcome!";
        case "Register":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Create an account";
        case "Lock":
          this.paragraphs = "Better to be safe than sorry.";
          return "Lock screen";
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tatActive{
  border-bottom:  3px solid  #1A73E8 !important;
}
.auth-pages{
  background-image: url("../../assets/image/bg.png");
  background-size: 100% 100%;
}
//.v-slide-group__prev {
//  display: none !important;
//}
</style>
