<template>
  <router-view></router-view>
</template>

<script>
// import HelloWorld from './components/HelloWorld';

import axios from "axios";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'App',
  computed: {
  },
  components: {
    // HelloWorld,
  },
  created() {
    // this.$store.dispatch('vipDeploy')
    // this.loading();
  },
  methods:{
    // async loading(){
    //
    //     this.yyList.forEach(item => {
    //       axios.get("/deploy/"+item.code+".json?v="+ this.$appVersion).then(({ data }) => {
    //       })
    //  })
    // },
    // async loadingJs(){
    //
    //   this.$http.get("/deploy/zh.json",{})
    //       .then(response =>{
    //         console.log('js加载成功：',response)
    //       })
    // }
  },

  data: () => ({
    //
  }),
};
</script>
<style>
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left:0;
}

.text-overflow1{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.text-overflow2{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.text-overflow3{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.zd-bg-primary{
  background-color: #7b42fd !important;
}
body,html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

</style>
