export default {
	"HOME": "ホーム",
	"COMBO": "カスタマイズされたパッケージ",
	"VIP": "VIPエクスペリエンス",
	"TRAELING": "旅行ガイド",
	"ABOUT": "私たちについて",

	"ban": "残高不足。",
	"联系我们": "お問い合わせ",
	"简体中文": "簡体字中国語",
	"游泳池": "プール",
	"热水浴池": "温泉",
	"退款额高": "高い払い戻し額",
	"匹配VIP伴游": "VIPコンパニオンマッチング",
	"行程时间": "旅行時間",
	"出行人数": "旅行者数",
	"旅行总花费": "総旅行費用",
	"定制同款行程": "同様の旅程のカスタマイズ",
	"detailsInfo": "あなたが迷って、疲れて、悲しんでいるとき、旅行に出かけましょう。なぜなら、異なる世界を見ることができるからです。あなたも私と同じように、旅先で美食と景色を楽しむだけでなく、ユニークな愛に出会うことができるかもしれません。私たちの目が合ったとき、私たちは多くの面白い経験について話し、さまざまな食べ物や景色を共有しました。この出会いが私たちをお互いに近づけました。彼は音楽を理解し、絵を描き、優しい心を持っています。私たちは一緒に現地の文化を体験し、地元の食べ物を味わい、自然の平和で美しい生活を感じました。私たちは一緒に楽しい旅を過ごしました。後で、私たちはその素晴らしい出会いを懐かしみ、お互いに素晴らしい気持ちを持っていたので、連絡を取り合いました。人生は一度は衝動的でなければなりません。誰かのため、愛のため、旅行のため、または夢のために。",
	"定制成功": "カスタマイズ成功",
	"热门定制套餐": "人気のカスタムパッケージ",
	"Ta们的旅行故事": "彼らの旅の物語",
	"达人旅游攻略": "エキスパートの旅行ガイド",
	"全球高端私人定制": "グローバルハイエンドプライベートカスタマイズ",
	"企业团建商旅": "企業チームビルディングビジネストラベル",
	"游艇俱乐部": "ヨットクラブ",
	"别墅轰趴": "別荘パーティー",
	"豪车租赁": "高級車のレンタル",
	"私人飞机": "プライベートジェット",
	"私人定制": "プライベートカスタマイズ",
	"VIP定制": "VIPカスタマイズ",
	"本会员解释权归本公司所有": "この会員の解釈権は当社にあります",
	"VIP会员尊享A": "VIP会員特典A",
	"会员尊享介绍": "会員特典紹介",
	"可享受退款": "払い戻し可能",
	"游伴随机匹配": "ランダムにマッチングされた同行者",

	"VIP会员尊享B": "VIP会員特典B",
	"VIP会员尊享C": "VIP会員特典C",
	"VIP_B_INFO": "要件に応じて同行者をマッチングできます<br>現地のエリートパーティを楽しむことができます",
	"VIP_C_INFO": "要件に応じて同行者をマッチングできます<br>社交界やビジネスエリートのパーティへの入場券、限られた枠のハイエンドビジネスミーティング",

	"about":{
		"fya1": "サービス範囲はアジア、ヨーロッパ、オセアニア、北極と南極を含む七大陸の数百の目的地をカバーしています。",
		"fya2":"主な事業",
		"fya3":"グローバル ハイエンドのプライベート カスタマイズ",
		"fya4":"企業チームビルディング出張",
		"fya5":"ヨットクラブヴィラパーティー",
		"fya6":"高級レンタカー",
		"fya7":"プライベートジェット",
		"fya8":"クラシック ルート + SVIP カスタマイズ",
		"fya9": "目的地ハイエンドの新しいゲームプレイ + 究極のサービス",
		"fyb1": "主な機能とハイライトは、目的地のハイエンドの新しいゲームプレイと究極のサービスです。",
		"fyb2":"グローバル ハイエンドのプライベート カスタマイズ",
		"fyb3": "Hand In Handは顧客のために特別に設計された旅行を提供し、手作りの旅程に対する現地サポート、プライベートガイド、プライベートカーを提供しています。同社のハイエンドな旅行商品には、アドベンチャーツアーやカスタマイズツアー、ファミリーツアーや同行ツアー、ハネムーンツアーや小型船ツアーなどが含まれています。Hand In Handの世界中に広がる旅行ネットワークは大きな利点であり、多くの世界各地の目的地に対して個人向けの高級旅行を実現します。プライベートカスタムトラベル、あなた自身の旅行スタイルをカスタマイズする。",
		"fyb4":"コアアドバンテージ",
		"fyb5":"ソウルメイト VS 仲間",
		"fyb6":"製品の特徴",
		"fyb7": "一流のチケット エージェント、実際に手に入るホテルのリソース、斬新で興味深い目的地のゲームプレイ、カスタマイザーによるルート プランの 1 対 1 の設計",
		"fyb8":"航空券やホテルの代理店は数多くあり、製品ソリューションは著しく均質化されています。",
		"fyb9":"サービスメソッド",
		"fyc1":"24 時間のトラベル バトラーによるプロセス全体の追跡、顧客のニーズや問題に対するワンストップ ソリューション",
		"fyc2": "問題が発生したときに誰も見つけられず、解決策は包括的かつタイムリーではありません",
		"fyc3":"サービス範囲",
		"fyc4":"私有島や無人地帯を含め、交通機関でアクセスできるあらゆる場所をカスタマイズできます",
		"fyc5":"利用できる目的地は限られています",
		"fyc6":"行&鲁",
		"fyc7":"タイ/サイパン/カンボジアおよびその他の世界各地のトップフリート (ロールスロイス、ベントレー、キャデラック 'アーミー ワン...) 広東省、香港、マカオの国境を越えた直接自主運用フリート (トヨタ アルファ シリーズ) )",
		"fyc8":"行&空",
		"fyc9":"プライベート ジェット/ヘリコプター/ビジネス ジェット (ACS エア グローバル/シュプリーム エアなど) 世界中の目的地へのファーストクラス航空券代理店 (ビジネス クラス、ファースト クラス、エコノミー クラス)",
		"fyd1":"行&海",
		"fyd2":"タイ/サイパン/深センおよびその他の世界の目的地への豪華ヨットによる南極世界クルーズルート",
		"fyd3":"エンターテイメントとクラブ",
		"fyd4":"博華宮 LUCKYKTVOFSAIPAN (会社固定資産)",
		"fyd5":"エンターテイメント&遊び",
		"fyd6": "世界の人気の目的地をカスタマイズする新しい方法を作成し、タイ/サイパンやその他の場所ですべての海、陸、空のリソースを利用可能にします。世界のトップホテル/スパ/ゴルフクラブ/スーパーカーとの緊密な協力クラブ",
		"fyd7":"ソウルメイトメンバーシップ",
		"fyd8":"優れたメンバーシップ システムを作成し、アイデンティティをアップグレードし、ステータスを強調します。Hand In Hand メンバーシップ カード (アカウント開設の基準額あり) を所有する VIP は、旅行商品の予約やトップ バーを含むスター レベルの限定特典を享受できます。 、高級車やヨット、トップスパのクラブハウス、世界クラスの食事の楽しみ、高級ブランドのショッピング割引など、24時間体制の専門チームが終日サービス、リアルタイムのフォローアップ、オーダーメイドを提供します。旅のプランを枠を超えて、無限の可能性を創造します。",
		"fyd9":"会社紹介",
		"fye1": "Hand In Handは、5,000万の登録資本を持ち、世界中のハイエンド旅行サービスに特化した企業です。高品質の旅行を追求する人々のために、ユニークで究極の贅沢な体験を提供することに重点を置いています！<br><br> 設立以来、当社のサービス範囲はアジア、ヨーロッパ、オセアニア、南北極を含む世界7大陸の数百の目的地をカバーしており、各地で大規模かつ専門的な地接チームを展開しています。タイやサイパンなどの地域では、独自のブランド別荘、フリート、地接を所有し、目的地のトップクラスで経験豊富な旅行サービスプロバイダーと直接提携しています。<br><br> 豊富なハイエンドサービスの経験と幅広いリソース開発チャネルを活かし、社会的エリートやハイエンドの顧客に対して個人に特化したサービスを提供し、企業にはクリエイティブなチームビルディングのカスタマイズ、高品質な旅行リソースの供給、特色ある製品の開発などを行い、包括的で高品質なサービスプランニングを提供し、厳選されたトップクラスの旅行プランやカスタマイズされた旅行サービスを提供しています。",

		"fye2":"この段落は私たちについての紹介です",
		"fye3": "ハンドインハンド株式会社は、中国の深センに本社を置き、登録資本5000万を有し、世界中のハイエンド旅行サービスに特化した企業です。高品質の旅行を追求する人々のために、ユニークで究極の贅沢な体験を提供することに重点を置いています！設立以来、当社のサービス範囲はアジア、ヨーロッパ、オセアニア、南北極を含む世界7大陸の数百の目的地をカバーしており、各地で大規模かつ専門的な地接チームを展開しています。タイやサイパンなどの地域では、独自のブランド別荘、フリート、地接を所有し、目的地のトップクラスで経験豊富な旅行サービスプロバイダーと直接提携しています。豊富なハイエンドサービスの経験と幅広いリソース開発チャネルを活かし、社会的エリートやハイエンドの顧客に対して個人に特化したサービスを提供し、企業にはクリエイティブなチームビルディングのカスタマイズ、高品質な旅行リソースの供給、特色ある製品の開発などを行い、包括的で高品質のサービスプランニングを提供し、厳選されたトップクラスの旅行プランやカスタマイズされた旅行サービスを提供しています。",
	},

	"qt":{
		"fya1":"料金の説明",
		"fya2":"手数料込み",
		"fya3":"車の料金:",
		"fya4":"プライベートカスタマーサービス:",
		"fya5":"チケット料金:",
		"fya6":"手数料は含まれません",
		"fya7":"ビザ:",
		"fya8":"ビザ手数料は含まれておらず、旅行・訪問ビザ代行サービスも提供可能",
		"fya9":"チケット:",
		"fyb1":"航空券代全額含まず、国際線・国内線航空券予約サービスも可能",
		"fyb2":"ホテル:",
		"fyb3":"ホテル宿泊費全額は含まれません",
		"fyb4":"残業代:",
		"fyb5":"別途の空港送迎を除き、ドライバーの所定労働時間は1日10時間（09:00～19:00）となります。制限時間を超える場合は、時間外料金を前払いしていただきます。",
		"fyb6":"自己資金プロジェクト:",
		"fyb7":"旅程の自己負担によるオプションのアクティビティ、チケット、食事は見積もりに含まれていません",
		"fyb8":"個人的な雑費:",
		"fyb9":"ケータリング、飲み物、ランドリー代、電話代、タバコ代、アルコール代、有料テレビ、インターネット代、FAX、手荷物取扱料、個人消費、ホテル内で発生したチップなどの個人的な費用が含まれます",
		 "fyc1":"追加料金:",
		"fyc2":"交通の遅延、ストライキ、天候、航空機や機械の故障、フライトのキャンセルや時間の変更などの不可抗力の理由によって発生した追加費用",
		"fyc3":"その他の料金:",
		"fyc4":"記載されていないその他すべての項目",
		"fyc5":"予約手順",
		"fyc6":"予約手順",
		"fyc7":"1. 旅行者の体調が旅行商品の旅程に適していることを確認する必要があります。",
		"fyc8":"2. 予約エラーや旅行への影響を避けるため、ご予約の際は必ず正確かつ完全な情報（性別、国籍、連絡先情報、大人か子供かなど）をご提供ください。誤った個人情報を提供した場合、生じた経済的損失はお客様が負担するものとします。",
		"fyc9":"3. お客様に代わって当社の代理店にビザの申請を依頼する場合は、予約注文を確認した後すぐにビザに必要な資料を準備し、ビザの資料が本物で完全であることを必ず確認してください。私たちの代理店が指定する最終期限までに、私たちのクラブに来てください。",
		"fyd1": "4. 旅行中は、当該国や地域の関連規制、現地の公共の場の秩序規則に従うべきであり、現地の宗教的信念、民族習慣、地元の状況を尊重し、地元の自然環境を自覚的に保護するべきです。国（地域）の関連規制に違反し、過剰な通貨、商品、または禁制品を携帯した場合、すべての結果は自己負担となります。",
		"fyd2":"5. 本旅行商品は、閑散期・繁忙期の変化に伴い、内容が頻繁に変更されます。商品紹介、旅程、料金体系等は参考情報です。最終的な商品内容は変更される場合がございます。双方による確認。",
		"fyd3":"6. お客様が取り決めた旅程に従って旅行に間に合わなかった場合、またはお客様の都合により旅行商品の一部が使用されなかった場合は、旅行商品の使用を断念したものとみなします。ご自身のものであり、当社の代理店は料金を返金しません。",
		"fyd4":"7. 不可抗力により旅行の実施または継続が不可能となった場合、当社は、お客様の同意を得た上で、当該旅行内容を変更することがあります。 緊急の事情により相談が不可能な場合、またはご本人の同意を得ることができない場合。 「当社は、相談の上、同意するものとします。内容を変更することは当社が決定することができますが、その決定に必要な証拠を提出する必要があります。当社は、お客様が生じた経済的損失について、保険会社への賠償請求のお手伝いをいたします。",
		"fyd5":"8. 入国管理局、各国領事館、航空会社、保険会社その他の当社の責に帰すべき事由によらない事由によりお客様または旅行者の人身または財産の権利利益が侵害された場合ただし、フライトの遅延や欠航、交通規制、パスポートの延長、ビザの拒否や期限内にビザを発行できないこと、出入国制限などに限定されるものではありません。時間内に当社の代理店に電話することができます。可能な範囲で積極的に支援し、トラブルの軽減に努めます。損失やその他の問題は、お客様自身の交渉によって解決されるものとします。",
		"fyd6":"9. お客様の権利を保護するため、旅行商品のサービスが必要な場合、または旅行中に旅行上の問題が発生した場合は、24 時間対応の保護ホットライン までお電話いただき、ご相談ください。 ",
		 "fyd7":"キャンセル・変更ルール",
		"fyd8":"1. 製品に含まれるアイテムはお客様のニーズに合わせてカスタマイズされているため、キャンセルと変更のルールについては実際の旅程確認フォームを参照してください。",
		"fyd9":"2. 旅行商品に含まれる国際線区間の航空券については、航空券確認書に基づき払い戻し・変更規定が適用されます。",
	}

}
