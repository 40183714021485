import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import vuetify from './plugins/vuetify'
import DashboardPlugin from "./plugins/dashboard-plugin";

import { library } from '@fortawesome/fontawesome-svg-core'
//按需
// import { faCoffee,faUserSecret,faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//按全部
import { fas } from '@fortawesome/free-solid-svg-icons'

import store from './store'
// VueI18n
import VueI18n from '@/common/vue-i18n.min.js';
import zh from '@/common/locales/zh.js';
import zh_tw from '@/common/locales/zh_tw.js';
import ar from '@/common/locales/ar.js';
import jp from '@/common/locales/jp.js';
import kr from '@/common/locales/kr.js';
import en from '@/common/locales/en.js';
// VueI18n
Vue.use(VueI18n);
let lang = localStorage.getItem('goTravel_lang');
if(!lang){
  lang = 'en';
  localStorage.setItem('goTravel_lang','en');
}
const i18n = new VueI18n({
  // 默认语言
  locale: lang,
  // 引入语言文件
  messages: {
    'zh': zh,
    'zh_tw': zh_tw,
    'ar': ar,
    'jp': jp,
    'kr': kr,
    'en': en,
  }
});

Vue.prototype.$appVersion = '3.1.1';

import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.config.productionTip = false

Vue.use(DashboardPlugin);

import axios from 'axios'
//
// Vue.prototype.$http = axios;
// axios.defaults.baseUrl='https://api.maxstarapp.org/'

const axiosConfig = {
  baseURL: 'https://api.maxstarapp.org/',
  timeout: 30000,
};

Vue.prototype.$http = axios.create(axiosConfig)

new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
