export default {

  "HOME":"首頁",
  "COMBO":"客製化套餐",
  "VIP":"尊享VIP",
  "TRAELING":"旅遊攻略",
  "ABOUT":"關於我們",
  "ban":"余额不足.",
  "联系我们":"聯絡我們",
  "简体中文":"繁體中文",

  "游泳池":"泳池",
  "热水浴池":"熱水浴池",
  "退款额高":"退款額高",
  "匹配VIP伴游":"匹配VIP伴遊",

  "行程时间":"行程時間",
  "出行人数":"出遊人數",
  "旅行总花费":"旅行總花費",
  "定制同款行程":"客製化同款行程",
  "detailsInfo":"在你茫然，疲憊，難過的時候，來一段旅行吧，因為你可以看到不一樣的世界，也許你可以跟我一樣，享受旅途中美食美景的同時，還能邂逅一份獨一無二的愛情我和 他的眼神接觸的時候，我們聊了很多有趣的經歷，分享了各種美食和美景，這次邂逅讓我們慢慢靠近了彼此，他懂音樂，會畫畫，有一顆溫柔善良的心，我們 一起體驗當地文化，品嚐了當地的美食，一起感受寧靜美好的大自然生活我們在一起度過了愉快的一段旅程，後來我們保持著聯繫，因為我們都想念著那一次的美好邂逅，對彼此都 有著美妙的感覺人生一定要衝動一次，要嘛為了一個人，要嘛為了愛情，要嘛是為了旅行，要嘛是為了夢想",
  "定制成功":"客製化成功",

  "热门定制套餐":"熱門客製化套餐",
  "Ta们的旅行故事":"Ta們的旅遊故事",
  "达人旅游攻略":"達人旅遊攻略",
  "全球高端私人定制":"全球高端私人訂製",
  "企业团建商旅":"企業團建商旅",
  "游艇俱乐部":"遊艇俱樂部",
  "别墅轰趴":"別墅轟趴",
  "豪车租赁":"豪華汽車租賃",
  "私人飞机":"私人飛機",

  "私人定制":"私人訂製",

  "VIP定制":"VIP客製化",
  "本会员解释权归本公司所有":"本會員解釋權歸本公司所有",
  "VIP会员尊享A":"VIP會員尊享A",
  "会员尊享介绍":"會員尊享介紹",
  "可享受退款":"可享退款",
  "游伴随机匹配":"遊伴隨機匹配",
  "VIP会员尊享B":"VIP會員尊享B",
  "VIP会员尊享C":"VIP會員尊享C",
  "VIP_B_INFO":"可依要求配對遊伴<br>可享旅行當地精英patty",
  "VIP_C_INFO":"可依要求配對遊伴<br>名媛party和商人名流Patyy 入場券，限名額「高端商務見面會」",


  "about":{
    "fya1":"服務範圍涵蓋亞洲，歐洲，大洋洲，南北極等全球七大洲上百個目的地",
    "fya2":"主營事業",
    "fya3":"全球高端私人訂製",
    "fya4":"企業團建商旅",
    "fya5":"遊艇俱樂部別墅轟趴",
    "fya6":"豪華汽車租賃",
    "fya7":"私人飛機",
    "fya8":"經典路線+SVIP訂製",
    "fya9":"目的地高階新玩法+極致的服務",
    "fyb1":"主要特色亮點為目的地高端新玩法和極致的服務",
    "fyb2":"全球高端私人訂製",
    "fyb3":"Hand In Hand為客戶量身訂做的旅遊，提供實地支援的手工行程，私人導遊和私家車，公司的高端旅行產品包括探險遊，定制遊，家庭遊，陪同遊，蜜月遊，小船遊等等 。 遍布世界各地的旅遊網絡是Hand In Hand的一大優勢，因此私人豪華訂製旅行可達全球眾多目的地。 私旅定制，定制屬於你的旅行方式。",
    "fyb4":"核心優勢",
    "fyb5":"Hand In Hand VS 同行",
    "fyb6":"產品特色",
    "fyb7":"票一級代理商，飯店一手資源，目的地玩法新穎有趣，定制師一對一設計路線方案",
    "fyb8":"機票飯店層層代理，產品方案同質化嚴重",
    "fyb9":"服務方式",
    "fyc1":"24小時旅行管家全程跟踪，一站式解決客戶需求或問題",
    "fyc2":"問題出現找不到人，解決方案不全面不及時",
    "fyc3":"服務範圍",
    "fyc4":"只要是交通工具可達地，都可客製化，包括私人海島以及無人區",
    "fyc5":"可供目的地有限",
    "fyc6":"行&陸",
    "fyc7":"泰國/塞班/柬埔寨等全球各目的地頂級車隊（勞斯萊斯，賓利，凱迪拉克'陸軍一號..）粵港澳跨境直通自營車隊（豐田埃爾法系列）" ,
    "fyc8":"行&空",
    "fyc9":"私人飛機/直升機/公務機（如ACS艾爾環球/至尊航空） 全球目的地一級機票代理（商務艙，頭等艙，經濟艙）",
    "fyd1":"行&海",
    "fyd2":"泰國/塞班島/深圳等全球目的地豪華遊艇南北極全球遊輪路線",
    "fyd3":"娛&會所",
    "fyd4":"博華皇宮LUCKYKTVOFSAIPAN (公司固定資產）",
    "fyd5":"娛&玩",
    "fyd6":"打造全球熱門目的地客製化新玩法&海陸空資源一應俱全泰國/塞班等各地網紅餐廳深度合作全球頂級酒店/水療會所/高爾夫俱樂部/超跑俱樂部",
    "fyd7":"Hand In Hand會員制",
    "fyd8":"開創尊貴會員制，升級身份，突顯地位。手持Hand In Hand會員卡（有開戶資金門檻）的貴賓，可獨享星級禮遇涵蓋旅遊產品預訂，頂尖酒吧，豪車遊艇，頂級水療 會所，世界級餐飲享受，奢華品牌購物優惠等，另設24小時專業團隊全天服務，實時跟進，量身訂做週遊行程。超脫框架，開創無限可能性。",
    "fyd9":"公司介紹",
    "fye1": "Hand In Hand，註冊資金5000萬，是一家專注全球高端旅行服務商。著重為追求高品質旅行夢想者打造獨一無二、極致樂享的輕奢體驗！<br><br>成立至今，我們的服務範圍覆蓋亞洲，歐洲，大洋洲，南北極等全球七大洲上百個目的地，在當地陸續建立龐大且專業的地接隊伍，在泰國，塞班島等地擁有自己的品牌別墅，車隊，地接，同時與目的地實力一流和經驗豐富的旅遊服務商均是直屬合作。<br><br>豐富的高端服務經驗，廣泛的一手資源開發渠道，在人無我有的極致化服務體系上，為高端客戶社會精英提供私人定制服務，為公司提供創意團建定制，優質旅行資源供應，特色產品研發等，提供全包式的高質量服務規劃，提供各項精選頂級旅遊行程與量身訂做的旅遊服務。",
    "fye2":"這段是關於我們的一個介紹",
    "fye3":"Hand In Hand 有限公司中國總部位於深圳，註冊資金5000萬，是一家專注全球高端旅遊服務商。著重為追求高品質旅行夢想者打造獨一無二、極致樂享的輕奢體驗！成立至今 ，我們的服務範圍涵蓋亞洲，歐洲，大洋洲，南北極等全球七大洲上百個目的地，在當地陸續建立龐大且專業的地接隊伍，在泰國，塞班島等地擁有自己的品牌別墅， 車隊，地接，同時與目的地實力一流和經驗豐富的旅遊服務商均是直屬合作。豐富的高端服務經驗，廣泛的一手資源開發管道，在人無我有的極致化服務體系上，為高端 客戶/社會菁英提供私人客製化服務，為公司提供創意團建定制，優質旅遊資源供應，特色產品研發等，提供全包式的高品質服務規劃，提供各項精選頂級旅遊行程與量身訂做 的旅遊服務。",
    "fye4":"",
  },

  "qt":{
    "fya1":"費用說明",
    "fya2":"費用包含",
    "fya3":"用車費用：",
    "fya4":"私人客服：",
    "fya5":"門票費用：",
    "fya6":"費用不含",
    "fya7":"簽證：",
    "fya8":"不含簽證費，可提供旅遊/探親簽證代辦服務",
    "fya9":"機票：",
    "fyb1":"不含全程機票費，可提供國際/國內機票代訂服務",
    "fyb2":"飯店：",
    "fyb3":"不含全程飯店住宿費用",
    "fyb4":"超時費：",
    "fyb5":"除單獨的接送機以外，司導每日常規工作時間10小時（09:00-19:00），如超時，請現付超時費。",
    "fyb6":"自費項目：",
    "fyb7":"行程自選自費的活動、報價未包含的門票、餐點",
    "fyb8":"個人雜費：",
    "fyb9":"包括飯店內餐飲、飲品、洗衣費、電話費、菸酒、付費電視、網費、傳真、行李搬運費等私人費用，個人消費及其所引起的小費",
    "fyc1":"額外費用：",
    "fyc2":"因交通延阻、罷工、天氣、飛機、機器故障、航班取消或更改時間等不可抗力原因所導致的額外費用",
    "fyc3":"其他費用：",
    "fyc4":"中所有未提及的其它項目",
    "fyc5":"預訂須知",
    "fyc6":"預訂說明",
    "fyc7":"1. 您應確保出行人身體條件適合旅遊產品的行程安排。",
    "fyc8":"2. 請您在預定時務必提供準確、完整的資訊（包括性別、國籍、聯絡方式、是否成人或兒童等），以免產生預約錯誤，影響出行。如因您提供錯誤個人資訊 而造成的經濟損失須您自行承擔。",
    "fyc9":"3. 如需我社代為辦理簽證的，您需在確認預定訂單後請立即準備簽證所需材料，並務必在我社給出的最後截止時間前確保簽證材料真實完整的交付 至我社。",
    "fyd1":"4. 您在出遊期間，應遵循我國和目的地國家及地區的相關規定以及當地公共場所的相關秩序規則，尊重當地的宗教信仰、民族習慣和風土人情，自覺保護當地自然環境 。如您違反了國家（地區）的有關規定，攜帶過量貨幣、物品或違禁品而產生的一切後果須由您自行承擔。",
    "fyd2":"5. 本旅遊產品內容隨淡旺季變化調整較為頻繁，產品介紹、行程安排、價格構成等相關資訊僅供參考所用，最終產品內容請以雙方確認的為準。",
    "fyd3":"6. 如您未能按照約定的行程按時出行或因自身原因導致旅行產品中部分項目未使用的，即視為您自行放棄使用，我社不予退還任何費用。",
    "fyd4":"7. 因不可抗力導致無法履行或繼續出行的，我社可以在徵得您同意後，對相應出行內容予以變更。因情況緊急無法徵求意見或者經徵求意見無法得到您同意時， 本社可以決定內容的變更，但應就做出的決定提供必要的證明，由此產生的您經濟損失我社應協助您向保險公司索賠。",
    "fyd5":"8. 因出入境管理局、各國領館、航空公司、保險公司及其他有權機構等不歸責於我社的原因導致您或出行人人身、財產權益受到損害的，包括 但不限於航班延誤或取消、交通管制、護照延期、簽證拒簽或未按時出簽、不得出入境等，您可及時致電我社，我社在力所能及的範圍內予以積極協助，盡量爭取減少您的 損失，其他事項應由您自行協商解決。",
    "fyd6":"9. 為了保護您的權利，針對旅行產品的中的服務事項或旅行期間如有出行困難等需求的，您可撥打我社24小時保障電話進行諮詢並尋求幫助。 ",
    "fyd7":"退改規則",
    "fyd8":"1. 由於產品包含項目依顧客需求量身訂做，退改規則請參考實際行程確認單。",
    "fyd9":"2. 旅遊產品所包含的國際段機票，應依照依機票確認單的退改規則執行。",
  }

}

