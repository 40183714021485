<template>
  <v-app-bar
      color="rgba(255, 0, 0, 0)"
      height="auto"
      class="py-4  toolbar-content-padding-y-none"
      flat
  >
    <v-container>
      <v-row>
        <v-col cols="3" class="d-flex align-center">
          <img src="@/assets/logo.png" alt="Brooklyn" width="210"/>
        </v-col>

        <v-col cols="9" class="mx-auto text-center d-flex" style="justify-content: flex-end;">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-menu
                v-for="item in links"
                :key="item.name"
                open-on-hover
                offset-y
                bottom

                max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    color="transparent"
                    :class="item.name==$route.name ? 'zd-text-primary' : 'text-white'"
                    class="
                    font-weight-light
                    text-capitalize
                    ls-0
                    align-center
                  "
                    depressed
                    link
                    :to="item.link"
                >
                  {{ item.name}}
                  <v-img v-if="item.name !=='Home'"
                      src="@/assets/image/down.png"
                      alt="down-arrow"
                      class="arrow ms-1 d-lg-block d-none"
                      max-width="24"
                  />
                </v-btn>
              </template>

              <v-list v-if="item.name !== 'Home'" class="py-0 ">
                <template v-if="item.name == 'Movies'">
                  <app-bar-movie></app-bar-movie>
                </template>
                <template v-if="item.name == 'tvShows'">
                  <AppBarTvShows></AppBarTvShows>
                </template>
<!--                <template v-if="item.name == 'Video'">-->
<!--                  <AppBarVideo></AppBarVideo>-->
<!--                </template>-->
                <template v-if="item.name == 'About The Company'">
                  <AppBarAbout></AppBarAbout>
                </template>
              </v-list>
            </v-menu>

          </template>
          <v-btn
              v-show="$vuetify.breakpoint.mobile"
              elevation="0"
              :ripple="false"
              height="43"
              class="
              font-weight-600
              text-capitalize
              drawer-toggler
              btn-toggler-hover
              py-3
              px-6
              rounded-sm
            "
              color="transparent"
              @click="dialog = true"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            </div>
          </v-btn>

          <v-dialog
              v-model="dialog"
              content-class="position-absolute top-0"
              width="95%"
          >
            <v-card class="card-shadow card-padding">
              <v-card-title
                  class="
                  pt-0
                  px-4
                  card-padding
                  text-h6
                  font-weight-bold
                  text-typo
                  justify-space-between
                  border-bottom
                "
              >
                Material Dashboard 2 PRO

                <v-btn icon width="31" :ripple="false" @click="dialog = false">
                  <v-icon size="18" class="text-typo">fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="card-padding pb-0 d-flex flex-column px-0">
                <v-btn
                    v-for="item in links"
                    :key="item.name"
                    :ripple="false"
                    text
                    class="text-typo text-capitalize ls-0"
                    depressed
                    link
                    :to="item.link"
                >
                  {{ item.name }}
                  <v-spacer></v-spacer>
                </v-btn>

                <div class="border-bottom my-7"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>

      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
import AppBarMovie from "./Widgets/AppBarMovie.vue";
import AppBarTvShows from "./Widgets/AppBarTvShows.vue";
import AppBarVideo from "./Widgets/AppBarVideo.vue";
import AppBarAbout from "./Widgets/AppBarAbout.vue";
export default {
  name: "app-bar",
  components: {
    AppBarMovie,AppBarTvShows,AppBarVideo,AppBarAbout

  },
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
  data() {
    return {
      dialog: false,
      links: [
        {
          name: "Home",
          link: "/pages/index/index",
        },
        {
          name: "Movies",
          link: "/pages/movies/index",
        },
        {
          name: "tvShows",
          link: "/pages/tvShows/index",        },

        // {
        //   name: "Video",
        //   link: "/pages/video/index",
        // },
        {
          name: "About The Company",
          link: "/pages/about/index",
        },
      ],
    };
  },
};
</script>
