<template>
  <v-list class="py-0 list-item-bg border-radius-xl">
    <v-list-item v-for="item in pages" :key="item.title" class="mb-0 ps-0 list-item-bg" >
      <v-btn
        :ripple="false"
        text
        class="text-white font-weight-normal text-capitalize ls-0"
        depressed
        link
        :to="item.link"
      >
        {{ item.title }}
<!--        <v-spacer></v-spacer>-->
      </v-btn>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "app-bar-authentication",
  data() {
    return {
      pages: [
        {
          title: "Video List",
          link: "/pages/video/list",
        },
        {
          title: "Video Style",
          link: "/pages/video/index",
        },
        {
          title: "Single Video",
          link: "/pages/video/single",
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.list-item-bg{
  background-color: #212121 !important;

}
.list-item-bg:hover{
  background-color: #7b42fd !important;

}
</style>