// store.js
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {

        details:null,

        inLogin:false,
        detailsBg:'',

        deploy: [],
        home:[],
        combo:[],
        tab:[],

        strategy:[],

        vip:[],
    },
    mutations: {
        setInLogin(state, value) {
            state.inLogin = value;
        },

        setDetailsBg(state, value) {
            state.detailsBg = value;
        },

        setDetails(state, value) {
            state.details = value;
        },

        setVipData(state,json) {
            state.vip = json;

        },

        setComboDeploy(state,json) {
            console.log(json)
            state.deploy = json;

            state.home = state.deploy.home;
            state.combo = state.deploy.combo;

            state.tab = state.deploy.tab;


            state.strategy = state.deploy.strategy;

        },

    },
    getters : {

        // getHomeData:(state) => (item) =>
        // {
        //     return state.home[item]
        // },
        // getComboData:(state) => (type) =>
        // {
        //     return state.combo.filter(todo => todo.type === type);
        // },
        ///获取当前ICON置表数据

    },
    actions: {
        // async vipDeploy (context) {
        //     const url = "/deploy/vip.json?t="+ Date.now();
        //     await axios.get(url).then(({ data }) => {
        //         context.commit('setVipData', data)
        //     })
        // },
        // async loadDeploy (context) {
        //     const code = localStorage.getItem('goTravel_lang');
        //     // const url = "/deploy/"+code+".json?t="+ Date.now();
        //     const url = "/deploy/"+code+".json?v="+ this.$appVersion
        //     await axios.get(url).then(({ data }) => {
        //         context.commit('setComboDeploy', data)
        //     })
        // },

    },
    // 其他 Vuex 存储配置
});