<template>
  <div class="bg-footer mt-10">
    <template v-if="auth">
      <v-footer color="transparent" style="color: white;padding: 0 100px;"
          class="text-center d-flex flex-column"
      >
        <v-row class="pt-12 pb-12" style="width: 100%;border-bottom: 1px solid rgba(255,255,255,0.1);" >
          <div class="d-flex flex-column text-left mt-6"  style="min-width: 600px">
            <img src="@/assets/logo.png" alt="Brooklyn" width="210"/>
            <div class="mt-6 " style="color: #999999">
              Global film and television, advertising revenue all in MaxStar Int TV
            </div>
            <div class="d-flex mt-6">
              <v-btn @click="openUrl(item.url)"
                  large
                  v-for="(item,index) in icons"
                  :key="index"
                  class="mr-4"
                  :icon="true"
                  variant="text"
                  color="white"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex text-left flex-column mt-6"  style="min-width: 480px">
            <div>About the company</div>
            <div class="d-flex" style="color: #999999">
              <div class="text-left flex-column mt-6" style="min-width: 260px">
                <p>Company Introduction</p>
                <p>Mode Introduction</p>
                <p>development history</p>
                <p>Mission Vision Values</p>
              </div>
              <div class="text-left flex-column pt-6" style="min-width: 200px">
                <p>Terms of Use</p>
                <p>Help Center</p>
                <p>Privacy Policy</p>
                <p>F&Q</p>
              </div>
            </div>
          </div>
          <div class="text-left mt-6"  style="width: 460px">
            Download Center
            <div class="text-left flex-column mt-6" style="color: #999999">
              Download the MaxStar Int Tv app and embark on your journey of watching movies and earning money!
            </div>
            <div class="d-flex mt-6 cursor">
              <v-img @click="onDown(1)"
                  max-width="200"
                  max-height="200"
                  src="@/assets/image/footer-iphone.png"
                  class="me-3"
              ></v-img>
              <v-img @click="onDown(2)"
                  max-width="200"
                  max-height="200"
                  src="@/assets/image/footer-google.png"
                  class="me-3"
              ></v-img>
            </div>
          </div>
        </v-row>




        <div class="pt-10">
          Copyright © GALAXYBITCOIN Asset Management Ltd.All rights reserved.
        </div>

        <v-divider></v-divider>
      </v-footer>

    </template>
    <template v-else>

    </template>
  </div>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  methods: {
    openUrl(url){
      window.open(url,'_blank') // 新窗口打开外链接
    },

    onDown(type){
      if(type == 1){
        let a = document.createElement('a');
        a.href = 'https://apps.apple.com/us/app/MaxstarintTV/6736990740';
        a.target = '__blank';
        a.click();
        a = null;
      }else{
        let a = document.createElement('a');
        a.href = 'https://play.google.com/store/apps/details?id=com.yinduduanju.apan';
        a.target = '__blank';
        a.click();
        a = null;
      }

    },
  },
  data() {
    return {
      links: [
        'Home',
        'About Us',
        'Team',
        'Services',
        'Blog',
        'Contact Us',
      ],

      icons: [
        {
          icon: "fab fa-facebook",
          url:"https://www.facebook.com/@MaxStarIntTV/",
        },
        {
          icon: "fab fa-telegram",
          url:"https://t.me/MaxStar_Int_TV_TV",
        },
        {
          icon: "fab fa-twitter",
          url:"https://x.com/MaxStarIntTV",

        },
        {
          icon: "fab fa-youtube",
          url:"https://www.youtube.com/@MaxStarInt_TV",
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">

.bg-footer{
  background-image: url("@/assets/image/bg-footer.png");
  background-size: 100% 100%;
}

.cursor{
  cursor:pointer;
}
</style>