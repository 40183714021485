export default {

  "HOME":"主页",
  "COMBO":"定制套餐",
  "VIP":"尊享VIP",
  "TRAELING":"旅游攻略",
  "ABOUT":"关于我们",
  "ban":"余额不足.",
  "联系我们":"联系我们",
  "简体中文":"简体中文",

  "游泳池":"游泳池",
  "热水浴池":"热水浴池",
  "退款额高":"退款额高",
  "匹配VIP伴游":"匹配VIP伴游",

  "行程时间":"行程时间",
  "出行人数":"出行人数",
  "旅行总花费":"旅行总花费",
  "定制同款行程":"定制同款行程",
  "detailsInfo":"在你茫然，疲惫，难过的时候，来一段旅行吧，因为你可以看到不一样的世界，也许你可以跟我一样，享受旅途中美食美景的同时，还能邂逅一份独一无二的爱情我和他的眼神接触的时候，我们聊了很多有趣的经历，分享了各种美食和美景，这次邂逅让我们慢慢靠近了彼此，他懂音乐，会画画，有一颗温柔善良的心，我们一起体验当地文化，品尝了当地的美食，一起感受宁静美好的大自然生活我们在一起度过了愉快的一段旅程，后来我们保持着联系，因为我们都想念着那一次的美好邂逅，对彼此都有很美妙的感觉人生一定要冲动一次，要么为了一个人，要么为了爱情，要么为了旅行，或者是为了梦想",
  "定制成功":"定制成功",

  "热门定制套餐":"热门定制套餐",
  "Ta们的旅行故事":"Ta们的旅行故事",
  "达人旅游攻略":"达人旅游攻略",
  "全球高端私人定制":"全球高端私人定制",
  "企业团建商旅":"企业团建商旅",
  "游艇俱乐部":"游艇俱乐部",
  "别墅轰趴":"别墅轰趴",
  "豪车租赁":"豪车租赁",
  "私人飞机":"私人飞机",

  "私人定制":"私人定制",

  "VIP定制":"VIP定制",
  "本会员解释权归本公司所有":"本会员解释权归本公司所有",
  "VIP会员尊享A":"VIP会员尊享A",
  "会员尊享介绍":"会员尊享介绍",
  "可享受退款":"可享受退款",
  "游伴随机匹配":"游伴随机匹配",
  "VIP会员尊享B":"VIP会员尊享B",
  "VIP会员尊享C":"VIP会员尊享C",
  "VIP_B_INFO":"可按要求匹配游伴<br>可享受旅行当地精英patty",
  "VIP_C_INFO":"可按要求匹配游伴<br>名媛party和商人名流Patyy 入场券，限名额「高端商务见面会」",


  "about":{
    "fya1":"服务范围覆盖亚洲，欧洲，大洋洲，南北极等全球七大洲上百个目的地",
    "fya2":"主营业务",
    "fya3":"全球高端私人定制",
    "fya4":"企业团建商旅",
    "fya5":"游艇俱乐部别墅轰趴",
    "fya6":"豪车租赁",
    "fya7":"私人飞机",
    "fya8":"经典路线+SVIP定制",
    "fya9":"目的地高端新玩法+极致的服务",
    "fyb1":"主要特色亮点为目的地高端新玩法和极致的服务",
    "fyb2":"全球高端私人定制",
    "fyb3":"Hand In Hand为客户量身订制的旅游，提供实地支持的手工行程，私人导游和私家车，公司的高端旅行产品包括探险游，定制游，家庭游，陪同游，蜜月游，小船游等等。遍布世界各地的旅行网络是Hand In Hand的一大优势，因此私人豪华定制旅行可达全球众多目的地。私旅定制，定制属于你的旅行方式。",
    "fyb4":"核心优势",
    "fyb5":"Hand In Hand\xa0\xa0\xa0\xa0VS\xa0\xa0\xa0\xa0同行",
    "fyb6":"产品特点",
    "fyb7":"票一级代理商，酒店一手资源，目的地玩法新颖有趣，定制师一对一设计路线方案",
    "fyb8":"机票酒店层层代理，产品方案同质化严重",
    "fyb9":"服务方式",
    "fyc1":"24小时旅行管家全程跟踪，一站式解决客户需求或问题",
    "fyc2":"问题出现找不到人，解决方案不全面不及时",
    "fyc3":"服务范围",
    "fyc4":"只要是交通工具可达地，都可定制化，包括私人海岛以及无人区",
    "fyc5":"可供目的地有限",
    "fyc6":"行&陆",
    "fyc7":"泰国/塞班/柬埔寨等全球各目的地顶级车队（劳斯莱斯，宾利，凯迪拉克'陆军一号..）粤港澳跨境直通自营车队（丰田埃尔法系列）",
    "fyc8":"行&空",
    "fyc9":"私人飞机/直升机/公务机（如ACS艾尔环球/至尊航空） 全球目的地一级机票代理（商务舱，头等舱，经济舱）",
    "fyd1":"行&海",
    "fyd2":"泰国/塞班岛/深圳等全球目的地豪华游艇南北极全球游轮路线",
    "fyd3":"娱&会所",
    "fyd4":"博华皇宫LUCKYKTVOFSAIPAN (公司固定资产）",
    "fyd5":"娱&玩",
    "fyd6":"打造全球热门目的地定制新玩法&海陆空资源一应俱全泰国/塞班等各地网红餐厅深度合作全球顶级酒店/水疗会所/高尔夫俱乐部/超跑俱乐部",
    "fyd7":"Hand In Hand会员制",
    "fyd8":"开创尊贵会员制，升级身份，突显地位。手持Hand In Hand会员卡（有开户资金门槛）的贵宾，可独享星级礼遇涵盖旅游产品预订，顶尖酒吧，豪车游艇，顶级水疗会所，世界级餐饮享受，奢华品牌购物优惠等，另设24小时专业团队全天服务，实时跟进，量身订造周游行程。超脱框架，开创无限可能性。",
    "fyd9":"公司介绍",
    "fye1":"Hand In Hand，注册资金5000万，是一家专注全球高端旅行服务商。着重为追求高品质旅行梦想者打造独一无二、极致乐享的轻奢体验！<br><br>成立至今，我们的服务范围覆盖亚洲，欧洲，大洋洲，南北极等全球七大洲上百个目的地，在当地陆续建立庞大且专业的地接队伍，在泰国，塞班岛等地拥有自己的品牌别墅，车队，地接，同时与目的地实力一流和经验丰富的旅游服务商均是直属合作。<br><br>丰富的高端服务经验，广泛的一手资源开发渠道，在人无我有的极致化服务体系上，为高端客户社会精英提供私人定制服务，为公司提供创意团建定制，优质旅行资源供应，特色产品研发等，提供全包式的高质量服务规划，提供各项精选顶级旅游行程与量身订做的旅游服务。",
    "fye2":"这段是关于我们的一个介绍",
    "fye3":"Hand In Hand 有限公司中国总部位于深圳，注册资金5000万，是一家专注全球高端旅行服务商。着重为追求高品质旅行梦想者打造独一无二、极致乐享的轻奢体验！成立至今，我们的服务范围覆盖亚洲，欧洲，大洋洲，南北极等全球七大洲上百个目的地，在当地陆续建立庞大且专业的地接队伍，在泰国，塞班岛等地拥有自己的品牌别墅，车队，地接，同时与目的地实力一流和经验丰富的旅游服务商均是直属合作。丰富的高端服务经验，广泛的一手资源开发渠道，在人无我有的极致化服务体系上，为高端客户/社会精英提供私人定制服务，为公司提供创意团建定制，优质旅行资源供应，特色产品研发等，提供全包式的高质量服务规划，提供各项精选顶级旅游行程与量身订做的旅游服务。",
    "fye4":"",
  },
  "qt":{
    "fya1":"费用说明",
    "fya2":"费用包含",
    "fya3":"用车费用：",
    "fya4":"私人客服：",
    "fya5":"门票费用：",
    "fya6":"费用不含",
    "fya7":"签证：",
    "fya8":"不含签证费，可提供旅游/探亲签证代办服务",
    "fya9":"机票：",
    "fyb1":"不含全程机票费，可提供国际/国内机票代订服务",
    "fyb2":"酒店：",
    "fyb3":"不含全程酒店住宿费用",
    "fyb4":"超时费：",
    "fyb5":"除单独的接送机以外，司导每日常规工作时间10小时（09:00-19:00），如超时，请现付超时费。",
    "fyb6":"自费项目：",
    "fyb7":"行程中自选自费的活动、报价未包含的门票、餐食",
    "fyb8":"个人杂费：",
    "fyb9":"包括酒店内餐饮、饮品、洗衣费、电话费、烟酒、付费电视、网费、传真、行李搬运费等私人费用，个人消费及其所引起的小费",
    "fyc1":"额外费用：",
    "fyc2":"因交通延阻、罢工、天气、飞机、机器故障、航班取消或更改时间等不可抗力原因所导致的额外费用",
    "fyc3":"其他费用：",
    "fyc4":"中所有未提及的其它项目",
    "fyc5":"预订须知",
    "fyc6":"预订说明",
    "fyc7":"1. 您应当确保出行人身体条件适合旅游产品的行程安排。",
    "fyc8":"2. 请您在预定时务必提供准确、完整的信息（包括性别、国籍、联系方式、是否成人或儿童等），以免产生预订错误，影响出行。如因您提供错误个人信息而造成的经济损失须您自行承担。",
    "fyc9":"3. 如需我社代为办理签证的，您需在确认预定订单后请立即准备签证所需材料，并务必在我社给出的最后截止时间前确保签证材料真实完整的交付至我社。",
    "fyd1":"4. 您在出行期间，应遵循我国和目的地国家及地区的有关规定以及当地公共场所的相关秩序规则，尊重当地的宗教信仰、民族习惯和风土人情，自觉保护当地自然环境。如您违反了国家（地区）的有关规定，携带过量货币、物品或违禁品而产生的一切后果须由您自行承担。",
    "fyd2":"5. 本旅行产品内容随淡旺季变化调整较为频繁，产品介绍、行程安排、价格构成等相关信息仅供参考所用，最终产品内容请以双方确认的为准。",
    "fyd3":"6. 如您未能按照约定的行程按时出行或因自身原因导致旅行产品中部分项目未使用的，即视为您自行放弃使用，我社不予退还任何费用。",
    "fyd4":"7. 因不可抗力导致无法履行或者继续出行的，我社可以在征得您同意后，对相应出行内容予以变更。因情况紧急无法征求意见或者经征求意见无法得到您同意时，我社可以决定内容的变更，但应当就做出的决定提供必要的证明，由此产生的您经济损失我社应协助您向保险公司索赔。",
    "fyd5":"8. 由于出入境管理局、各国领馆、航空公司、保险公司及其他有权机构等不可归责于我社的原因导致您或出行人人身、财产权益受到损害的，包括但不限于航班延误或取消、交通管制、护照延期、签证拒签或未按时出签、不得出入境等，您可及时致电我社，我社在力所能及的范围内予以积极协助，尽量争取减少您的损失，其他事项应由您自行协商解决。",
    "fyd6":"9. 为了保护您的权利，针对旅行产品的中的服务事项或旅行期间如有出行困难等需求的，您可拨打我社24小时保障电话进行咨询并寻求帮助。",
    "fyd7":"退改规则",
    "fyd8":"1. 由于产品包含项目根据客户需求定制，退改规则请参照实际行程确认单。",
    "fyd9":"2. 旅游产品所包含的国际段机票，应当按照按照机票确认单的退改规则执行。",
  }


}
