export default {

	"HOME": "الصفحة الرئيسية",
	 "COMBO": "حزم مخصصة",
	"VIP": "تجربة VIP",
"TRAELING": "دليل السفر",
	"ABOUT": "معلومات عنا",
	"ban": "رصيد غير كافٍ.",
	"联系我们": "اتصل بنا",
	"简体中文": "الصينية المبسطة",

	"游泳池": "حمام سباحة",
	"热水浴池": "حوض استحمام ساخن",
	"退款额高": "مبلغ استرداد عالي",
	"匹配VIP伴游": "مطابقة رفيق VIP",

	"行程时间": "وقت الجدول",
	"出行人数": "عدد المسافرين",
	"旅行总花费": "تكلفة السفر الإجمالية",
	"定制同款行程": "تخصيص نفس الجدول",
	"detailsInfo": "عندما تشعر بالضياع أو التعب أو الحزن، خذ رحلة، لأنك يمكن أن ترى عالمًا مختلفًا. ربما يمكنك أن تكون مثلي، تستمتع بالطعام والمناظر الطبيعية للرحلة، وتلتقي أيضًا بحب فريد. عندما التقت أعيننا، تحدثنا عن العديد من التجارب المثيرة وشاركنا مختلف الأطعمة والمناظر الطبيعية. هذا اللقاء جعلنا أقرب إلى بعضنا البعض. إنه يفهم الموسيقى، يمكنه الرسم، ولديه قلب حنون وطيب. عشنا ثقافة المكان معًا، ذوقنا الطعام المحلي، وشعرنا بالحياة السلمية والجميلة للطبيعة. قضينا رحلة ممتعة معًا. في وقت لاحق، بقينا على اتصال لأننا كنا نفتقد ذلك اللقاء الرائع وكان لدينا شعور رائع ببعضنا البعض. يجب أن تكون الحياة متهورة مرة واحدة، من أجل شخص ما، من أجل الحب، من أجل السفر، أو من أجل الأحلام.",
	"定制成功": "تم التخصيص بنجاح",

	"热门定制套餐": "حزم تخصيص شعبية",
	"Ta们的旅行故事": "قصص سفرهم",
	"达人旅游攻略": "نصائح السفر الخبير",
	"全球高端私人定制": "تخصيص خاص عالمي عالي النهاية",
	"企业团建商旅": "رحلات الأعمال وبناء الفريق الشركاتي",
	"游艇俱乐部": "نادي اليخوت",
	"别墅轰趴": "حفل فيلا",
	"豪车租赁": "تأجير سيارات فاخرة",
	"私人飞机": "طائرة خاصة",

	"私人定制": "تخصيص خاص",

	"VIP定制": "تخصيص VIP",
	"本会员解释权归本公司所有": "تعود تفسيرات هذه العضوية إلى الشركة.",
	"VIP会员尊享A": "امتيازات العضو VIP A",
	"会员尊享介绍": "مقدمة امتيازات العضو",
	"可享受退款": "يمكن الاستمتاع بالاسترداد",
	"游伴随机匹配": "مطابقة رفيق السفر عشوائيًا",
	"VIP会员尊享B": "امتيازات العضو VIP B",
	"VIP会员尊享C": "امتيازات العضو VIP C",
	"VIP_B_INFO": "يمكن مطابقته مع رفاق وفقًا للمتطلبات <br> يمكن الاستمتاع بحفلة نخبة محلية أثناء الرحلة",
	"VIP_C_INFO": "يمكن مطابقته مع رفاق وفقًا للمتطلبات <br> تذاكر دخول لحفلة اجتماعية وحفلة نخبة رجال الأعمال، حصة محدودة لاجتماعات الأعمال عالية المستوى",

	"about":{
		"fya1": "تغطي خدماتنا مئات الوجهات عبر جميع قارات العالم السبع، بما في ذلك آسيا وأوروبا وأوقيانوسيا والقطب الشمالي والجنوبي.",
		"fya2": "الأعمال الرئيسية",
		"fya3": "تخصيص عالمي فاخر",
		"fya4": "رحلات الأعمال وبناء الفريق الشركاتي",
		"fya5": "نادي اليخوت وحفلات الفيلات",
		"fya6": "تأجير سيارات فاخرة",
		"fya7": "طائرة خاصة",
		"fya8": "مسار كلاسيكي + تخصيص SVIP",
		"fya9": "وجهة لعب جديدة فاخرة + خدمة نهائية",
		"fyb1": "الميزات الرئيسية والملامح هي لعبة الوجهة الفاخرة الجديدة والخدمة النهائية",
		"fyb2": "تخصيص عالمي فاخر",
		"fyb3": "تقدم Hand In Hand رحلات مصممة خصيصًا للعملاء، وتوفر دعمًا ميدانيًا لجداول الرحلات اليدوية، والمرشدين الخاصين، والسيارات الخاصة. تشمل منتجات السفر عالية الجودة للشركة جولات المغامرة، والجولات المخصصة، وجولات العائلة، وجولات المرافقة، وجولات الشهر العسل، وجولات القوارب الصغيرة، وغيرها. الشبكة الواسعة للسفر حول العالم هي ميزة رئيسية لـ Hand In Hand، مما يسمح بالسفر الفاخر المخصص إلى العديد من الوجهات العالمية. السفر المخصص الخاص، تخصيص طريقتك الخاصة في السفر.",
		"fyb4": "الميزة الأساسية",
		"fyb5": "Hand In Hand مقابل النظراء",
		"fyb6": "ميزات المنتج",
		"fyb7": "وكيل تذاكر الدرجة الأولى، موارد الفنادق من مصادر أولية، لعبة وجهة جديدة ومثيرة، تصميم خطة مسار واحد للواحد من قبل المخصص",
		"fyb8": "هناك العديد من وكلاء تذاكر الطيران والفنادق، وحلول المنتج متجانسة بشكل جدي",
		"fyb9": "طريقة الخدمة",
		"fyc1": "خدمة البتلر السفري على مدار 24 ساعة طوال العملية، حل شامل لاحتياجات العميل أو المشاكل",
		"fyc2": "لا يمكن العثور على أي شخص عند حدوث المشكلة، والحل غير شامل وفي الوقت المناسب",
		"fyc3": "نطاق الخدمة",

		"fyc4": "طالما كانت وسيلة النقل يمكن الوصول إليها، يمكن تخصيصها، بما في ذلك الجزر الخاصة والأراضي البعيدة",
		"fyc5": "الوجهات المتاحة محدودة",
		"fyc6": "السفر والبر",
		"fyc7": "أساطيل رائدة في تايلاند/سايبان/كمبوديا وغيرها من الوجهات حول العالم (رولز رويس، بنتلي، كاديلاك 'الجيش الأول...). أساطيل مشتركة عابرة للحدود من قوانغدونغ وهونغ كونغ وماكاو (سلسلة تويوتا ألفا)",
		"fyc8": "السفر والجو",
		"fyc9": "طائرة خاصة/مروحية/طائرة رجال الأعمال (مثل ACS Air Global/Supreme Air) وكيل تذاكر طيران من الدرجة الأولى لوجهات عالمية (درجة رجال الأعمال، الدرجة الأولى، الدرجة الاقتصادية)",
		"fyd1": "السفر والبحر",
		"fyd2": "رحلة كروز فاخرة إلى القارة القطبية الجنوبية عبر يخت فاخر إلى تايلاند/سايبان/شنتشن وغيرها من الوجهات العالمية",
		"fyd3": "الترفيه والنوادي",
		"fyd4": "قصر بوهوا لوكيتفوفسايبان (أصول الشركة الثابتة)",
		"fyd5": "الترفيه واللعب",
		"fyd6": "إنشاء طريقة جديدة لتخصيص وجهات السفر الشهيرة في العالم وتوفير جميع الموارد البحرية والبرية والجوية المتاحة في تايلاند/سايبان وغيرها من الأماكن. تعاون عميق مع أفضل فنادق/منتجعات/نوادي الغولف/نوادي السيارات الفائقة في العالم",
		"fyd7": "عضوية Hand In Hand",
		"fyd8": "قم بإنشاء نظام عضوية مميز، وقم بترقية هويتك، وإبراز حالتك. يمكن لكبار الشخصيات الذين يحملون بطاقة عضوية Hand In Hand (مع حد مالي لفتح حساب) الاستمتاع بمزايا حصرية على مستوى النجوم تشمل حجوزات منتجات السفر، وأبرز الأشرطة والسيارات واليخوت الفاخرة، وأفضل المنتجعات الصحية، كلوب هاوس، والاستمتاع بتناول الطعام على مستوى عالمي، وخصومات التسوق من العلامات التجارية الفاخرة، وما إلى ذلك، وفريق محترف يعمل على مدار 24 ساعة لتقديم خدمة طوال اليوم، ومتابعة فورية، ومصممة خصيصًا مسارات السفر. تجاوز الإطار وأنشئ إمكانيات غير محدودة.",
		"fyd9": "مقدمة عن الشركة",

		"fye1": "هاند إن هاند، برأس مال مسجل قدره 50 مليون، هي مزود خدمات سفر فاخر عالمي. تركز على خلق تجارب فاخرة فريدة ونهائية لأولئك الذين يسعون لتحقيق أحلام السفر عالية الجودة! <br><br> منذ تأسيسها، تغطي خدماتنا مئات الوجهات عبر جميع قارات العالم السبع، بما في ذلك آسيا وأوروبا وأوقيانوسيا والقطب الشمالي والجنوبي. لقد أنشأنا فرقًا كبيرة ومحترفة للتعامل البري في هذه الوجهات، ولدينا فيلات مميزة وأساطيل وخدمات تعامل بالأرض في مواقع مثل تايلاند وسايبان. كما أن لدينا شراكات مباشرة مع مزودي خدمات السفر المحليين ذوي الخبرة والرائدين. مع الخبرة الغنية في خدمات الفاخرة وقنوات تطوير الموارد الأولية الواسعة، نقدم خدمات مخصصة للعملاء عاليي الدخل والنخبة الاجتماعية، بالإضافة إلى خدمات تخصيص الفرق الإبداعية للشركات، وتوريد موارد السفر عالية الجودة، وتطوير المنتجات الفريدة. نحن نقدم تخطيط خدمات شاملة عالية الجودة وخدمات سفر مصممة خصيصًا.",
		"fye2": "هذه الفقرة مقدمة عنا",
		"fye3": "تقع الشركة المحدودة هاند إن هاند الصينية في شنتشن، وتمتلك رأس مال مسجل بقيمة 50 مليون، وهي شركة متخصصة في تقديم خدمات سفر فاخرة عالمية. تركز بشكل كبير على خلق تجربة فاخرة فريدة ونهائية لأولئك الذين يسعون لتحقيق أحلام السفر عالية الجودة! منذ تأسيسها، تغطي خدماتنا مئات الوجهات عبر جميع قارات العالم السبع، بما في ذلك آسيا وأوروبا وأوقيانوسيا والقطب الشمالي والجنوبي. لقد أنشأنا فرقًا كبيرة ومحترفة للتعامل البري في هذه الوجهات، ولدينا فيلات مميزة وأساطيل وخدمات تعامل بالأرض في مواقع مثل تايلاند وسايبان. كما أن لدينا شراكات مباشرة مع مزودي خدمات السفر المحليين ذوي الخبرة والرائدين. مع الخبرة الغنية في خدمات الفاخرة وقنوات تطوير الموارد الأولية الواسعة، نقدم خدمات مخصصة للعملاء عاليي الدخل والنخبة الاجتماعية.",

	},

	"qt":{
		"fya1":"وصف الرسوم",
		"fya2":"الرسوم متضمنة",
		"fya3":"رسوم السيارة:",
		"fya4":"خدمة العملاء الخاصة:",
		"fya5":"رسوم التذكرة:",
		"fya6":"الرسوم لا تشمل",
		"fya7":"التأشيرة:",
		"fya8":"لا تشمل رسوم التأشيرة، يمكن توفير خدمة تأشيرة السياحة/زيارة",
		"fya9":"التذكرة:",
		"fyb1":"لا يتم تضمين رسوم تذكرة الطيران الكاملة، ويمكن توفير خدمة حجز تذاكر الطيران الدولية/المحلية",
		"fyb2":"الفندق:",
		"fyb3":"لا يشمل تكاليف الإقامة الكاملة في الفندق",
		"fyb4":"رسوم التأخير:",
		"fyb5":"باستثناء خدمة النقل من وإلى المطار بشكل منفصل، يعمل السائق لمدة 10 ساعات في اليوم الواحد (من الساعة 09:00 صباحًا حتى الساعة 07:00 مساءً)، في حال تجاوز الوقت المحدد، يُطلب دفع رسوم التأخير على الفور.",

		"fyb6":"المشاريع الممولة ذاتيًا:",
		"fyb7":"الأنشطة الاختيارية على نفقتك الخاصة في خط سير الرحلة، والتذاكر والوجبات غير المدرجة في عرض الأسعار",
		"fyb8":"المصروفات الشخصية المتنوعة:",
		"fyb9": "بما في ذلك النفقات الشخصية مثل الأطعمة والمشروبات، ورسوم غسيل الملابس، وفواتير الهاتف، والتبغ والكحول، والتلفزيون المدفوع، ورسوم الإنترنت، والفاكس، ورسوم التعامل مع الأمتعة، وما إلى ذلك في الفندق، والاستهلاك الشخصي والإكراميات الناجمة عن ذلك" ,
		"fyc1":"رسوم إضافية:",
		"fyc2": "النفقات الإضافية الناجمة عن أسباب قاهرة مثل تأخير حركة المرور، أو الإضرابات، أو الطقس، أو تعطل الطائرات أو الآلات، أو إلغاء الرحلات الجوية أو تغيير الوقت",
		"fyc3":"رسوم أخرى:",
		"fyc4":"جميع العناصر الأخرى غير المذكورة في",
		"fyc5":"تعليمات الحجز",
		"fyc6":"تعليمات الحجز",
		"fyc7":":1. يجب عليك التأكد من أن الحالة البدنية للمسافر مناسبة لبرنامج رحلة منتج السفر.",
		"fyc8": "2. يُرجى التأكد من تقديم معلومات دقيقة وكاملة عند الحجز (بما في ذلك الجنس، والجنسية، ومعلومات الاتصال، سواء كنت شخصًا بالغًا أو طفلاً، وما إلى ذلك) لتجنب أخطاء الحجز والتأثير على رحلتك. إذا كنت تقديم معلومات شخصية غير صحيحة، ويجب أن تتحمل أنت الخسائر الاقتصادية الناجمة عنها.",
		"fyc9":":3. إذا كنت تريد وكالتنا التقدم بطلب للحصول على تأشيرة نيابة عنك، فيجب عليك إعداد المواد المطلوبة للحصول على التأشيرة مباشرة بعد تأكيد أمر الحجز، والتأكد من أن مواد التأشيرة أصلية وكاملة قبل الموعد النهائي الذي حددته وكالتنا. تعال إلى نادينا.",
		"fyd1": "4. خلال سفرك، يجب عليك اتباع اللوائح ذات الصلة في بلدنا وبلد الوجهة أو المنطقة، فضلاً عن قواعد النظام ذات الصلة في الأماكن العامة المحلية، واحترام المعتقدات الدينية المحلية، والعادات العرقية، والظروف المحلية، وحماية البيئة الطبيعية المحلية بوعي. إذا خالفت اللوائح ذات الصلة في البلد (المنطقة)، وحملت كميات زائدة من العملة، أو البضائع، أو الأصناف المحظورة، فعليك تحمل جميع العواقب بمفردك.",
		"fyd2":":5. يتم تعديل محتوى منتج السفر هذا بشكل متكرر مع التغييرات في مواسم خارج أوقات الذروة ومواسم الذروة. مقدمة المنتج وخط سير الرحلة وهيكل الأسعار والمعلومات الأخرى ذات الصلة هي للإشارة فقط. ويخضع محتوى المنتج النهائي لـ تأكيد من الطرفين.",
		"fyd3": "6. إذا فشلت في السفر في الوقت المحدد وفقًا لخط سير الرحلة المتفق عليه أو إذا لم يتم استخدام بعض العناصر الموجودة في منتج السفر لأسباب خاصة بك، فسيتم اعتبار أنك قد تخليت عن استخدامه في الخاصة بك، ولن تقوم وكالتنا برد أي رسوم.",
		"fyd4": "7. إذا كان من المستحيل أداء الرحلة أو الاستمرار فيها بسبب قوة قاهرة، فيمكن لشركتنا تغيير محتوى السفر المقابل بعد الحصول على موافقتك. عندما يكون من المستحيل طلب المشورة بسبب ظروف عاجلة أو لا يمكن الحصول على موافقتك الموافقة بعد طلب المشورة، يمكن لشركتنا أن تقرر تغيير المحتوى، ولكن يجب علينا تقديم الدليل اللازم على القرار المتخذ. ستساعدك شركتنا في المطالبة بالتعويض من شركة التأمين عن خسائرك الاقتصادية الناتجة.",
		"fyd5": "8. إذا تضررت الحقوق والمصالح الشخصية أو الملكية لك أو للمسافر لأسباب لا تعزى إلى شركتنا، مثل إدارة الهجرة وقنصليات مختلف البلدان وشركات الطيران وشركات التأمين وغيرها المؤسسات المعتمدة، بما في ذلك، ومع ذلك، لا يقتصر الأمر على تأخير الرحلات أو إلغائها، أو مراقبة حركة المرور، أو تمديد جوازات السفر، أو رفض التأشيرات أو عدم إصدار التأشيرات في الوقت المحدد، أو قيود الدخول والخروج، وما إلى ذلك. يمكنك الاتصال بوكالتنا في الوقت المناسب، ووكالتنا ستساعدك بنشاط في حدود قدراتها وتحاول تقليل مشاكلك. سيتم حل الخسارة والمسائل الأخرى من خلال المفاوضات الخاصة بك.",
		"fyd6": "9. من أجل حماية حقوقك، يمكنك الاتصال بالخط الساخن للحماية على مدار 24 ساعة على الرقم للاستشارة والمساعدة إذا كانت لديك أي احتياجات للخدمات في منتجات السفر أو إذا كنت تواجه صعوبات في السفر أثناء رحلتك. ",
		"fyd7": "قواعد الإلغاء والتغيير",
		"fyd8": "1. بما أن العناصر المضمنة في المنتج مخصصة وفقًا لاحتياجات العملاء، يرجى الرجوع إلى نموذج تأكيد خط سير الرحلة الفعلي لمعرفة قواعد الإلغاء والتغيير.",
		"fyd9": "2. تخضع تذاكر الطيران الدولية المضمنة في منتجات السفر لقواعد الاسترداد والتغيير وفقًا لنموذج تأكيد تذكرة الطيران.",
	}



}
