<template>
  <v-app>
    <v-main class="auth-pages" style="background-color: #100F21;">
        <v-img
            :src="getBg"
            alt="pattern-lines"
            class="position-absolute opacity-6 start-0 top-0 w-100"
            height="400"
        ></v-img>
      <app-bar-auth style="z-index: 100;" class="mt-5 " :class="$vuetify.breakpoint.mobile?'':'shadow-lg'" background="" has-bg linkColor="white" @inLoginEvent="inLoginEvent">
      </app-bar-auth>
      <div style="height: 300px;width: 100%;z-index: 100;color: white;" class="position-relative justify-center d-flex flex-column align-center">
        <div style="font-size: 48px;font-weight: bold">{{$route.name}}</div>
        <div><router-link class="text-color-white" to="/">Home  <span style="margin: 0 10px"> > </span></router-link>  {{ $route.meta.groupName }}</div>
      </div>
      <div v-if="$route.name=='About Us' || $route.name=='Contact Us'" class="about-bg" style="" ></div>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container fluid class="">

          <router-view></router-view>
          <content-footer  auth></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition,CollapseTransition  } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "page-layout",
  computed: {
    ...mapState({
      inLogin: state => state.inLogin
    }),

    getBg() {
      return this.$route.name === 'Movies' ? require("@/assets/image/top-bg/top1.png") :
          this.$route.name === 'Tv Shows'? require("@/assets/image/top-bg/top2.png") :
          this.$route.name === 'Video'? require("@/assets/image/top-bg/top3.png") :
          this.$route.name === 'About Us'? require("@/assets/image/top-bg/top4.png") : require("@/assets/image/top-bg/top5.png");
    },


  },
  components: {
    AppBarAuth,
    FadeTransition,CollapseTransition ,
    ContentFooter,
  },
  data() {
    return {
      login:false,
      loginTime:500,
      form:{
        name:null,
        password:null,
      },
      showPassword: false,

      paragraphs: "",
      tab: null,
      active: true,
      // tabIndex:0,

    };
  },
  methods: {
    onLoginEvent(){
      this.$store.commit('setInLogin', true);
      this.login = false;
    },
    inLoginEvent(){
      this.login = !this.login;
      this.loginTime = this.login?500:50;
    },
    setTab(item){
      // this.tabIndex = item.type;
      console.log(item)
      if(item.type==2 && !this.inLogin){
        this.login = true;
        this.loginTime = this.login?500:50;
      }
    },
    // 忘记密码
    goPassword(){
      console.log('goPassword')
      this.$router.push({
        path:'/pages/login/password',
      })
    },

  },
};
</script>
<style scoped lang="scss">
.tatActive{
  border-bottom:  3px solid  #1A73E8 !important;
}
.router-link-active {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}

.content-list a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}

.about-bg{
  background-image: url("@/assets/image/about/bg.png");
  background-size: 100% 100%;
  position: absolute;
  height: 1358px;width: 100%;z-index: 0;
}

//.auth-pages{
//  //background-image: url("../../assets/image/bg.png");
//  background-size: 100% 100%;
//  background-color: #100F21;
//}
//.v-slide-group__prev {
//  display: none !important;
//}
</style>
