<template>
  <v-app>
    <v-main class="auth-pages ">
      <div
          class="
          header-auth
          position-relative
          ma-4
          pb-16
          pt-16
          border-radius-xl
          min-height-300
        "
          :style="{ backgroundImage: 'url(' + require('@/assets/image/bg.png') + ')',backgroundSize: 'cover', backgroundPosition: 'center'}"
      >


        <span
            class="mask bg-gradient-default border-radius-xl opacity-4"
        ></span>

        <v-container>
          <v-row class="d-flex mt-5">
            <v-col cols="12" md="6" class="mx-auto py-0 position-relative">
              <h1
                  class="
                  text-h1 text-white
                  font-weight-bolder
                  text-center
                  mb-2
                  mt-5
                "
              >
                {{ headerTitle() }}
              </h1>
              <p
                  class="
                  text-white
                  font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                "
              >
                {{ paragraphs }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <app-bar-auth class="shadow-lg " background="white" has-bg linkColor="white" @inLoginEvent="inLoginEvent">
      </app-bar-auth>

      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container fluid class="pb-10 px-0 px-md-8 px-lg-16  px-xl-20 px-xxl-20">

          <router-view></router-view>
                    <content-footer auth></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition,CollapseTransition  } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarAuth,
    FadeTransition,CollapseTransition ,
    ContentFooter,
  },
  data() {
    return {
      login:false,
      loginTime:500,

      paragraphs: "",
      tab: null,
      active: true,
      // tabIndex:0,
    };
  },
  methods: {
    inLoginEvent(){
      this.login = !this.login;
      this.loginTime = this.login?500:50;
    },
    setTab(item){
      // this.tabIndex = item.type;
      console.log(item)
    },
    // 忘记密码
    goPassword(){
      console.log('goPassword')
    },
    headerTitle() {
      switch (this.$route.name) {
        // case "Pricing":
        //   this.paragraphs =
        //       "You have Free Unlimited Updates and Premium Support on each package.";
        //   return "Pick the best plan for you";
        // case "SignUpBasic":
        //   this.paragraphs =
        //       "Use these awesome forms to login or create new account in your proje  ct for free.";
        //   return "Welcome!";
        case "Password":
          this.paragraphs =
              "Hand In Hand 欢迎您的回归！";
          return "帐户找回";
        case "Register":
          this.paragraphs =
              "Hand In Hand 欢迎您的加入！";
          return "创建一个帐户";
        default:
          break;
      }
    },
  },
};
</script>
<style>
.tatActive{
  border-bottom:  3px solid  #1A73E8 !important;
}
</style>
