export default {
	"HOME": "홈",
	"COMBO": "맞춤형 패키지",
	"VIP": "VIP 체험",
	"TRAELING": "여행 가이드",
	"ABOUT": "회사 소개",

	"ban": "잔고가 부족합니다.",
	"联系我们": "연락처",
	"简体中文": "간체 중국어",
	"游泳池": "수영장",
	"热水浴池": "온천",
	"退款额高": "높은 환불액",
	"匹配VIP伴游": "VIP 동행 매칭",
	"行程时间": "여행 시간",
	"出行人数": "여행자 수",
	"旅行总花费": "총 여행 비용",
	"定制同款行程": "동일한 여행 계획 맞춤 설정",
	"detailsInfo": "당신이 헤매고, 지칠 때, 슬플 때, 여행을 떠나세요. 왜냐하면 당신은 다른 세계를 볼 수 있기 때문입니다. 아마도 당신도 나와 같이 여행 중 음식과 풍경을 즐기는 동시에 독특한 사랑을 만날 수 있을지도 모릅니다. 우리 눈이 마주친 순간, 우리는 많은 재미있는 경험에 대해 이야기를 나누었고, 다양한 음식과 풍경을 공유했습니다. 이 만남은 우리를 서서히 가까이 가게 했습니다. 그는 음악을 이해하고, 그림을 그리며 부드럽고 친절한 마음을 가지고 있습니다. 우리는 함께 현지 문화를 경험하고 현지 음식을 맛보며 평화롭고 아름다운 자연의 삶을 느꼈습니다. 우리는 즐거운 여행을 함께했고, 나중에 우리는 서로를 그리워했기 때문에 연락을 유지했습니다. 삶은 한 번은 충동적이어야 합니다. 누군가를 위해, 사랑을 위해, 여행을 위해, 혹은 꿈을 위해.",
	"定制成功": "맞춤 설정 성공",
	"热门定制套餐": "인기 맞춤 패키지",
	"Ta们的旅行故事": "그들의 여행 이야기",
	"达人旅游攻略": "여행 전문가 팁",
	"全球高端私人定制": "글로벌 하이엔드 개인 맞춤 설정",
	"企业团建商旅": "기업 팀 빌딩 비즈니스 여행",
	"游艇俱乐部": "요트 클럽",
	"别墅轰趴": "빌라 파티",
	"豪车租赁": "럭셔리 카 렌탈",
	"私人飞机": "사설 비행기",
	"私人定制": "개인 맞춤 설정",
	"VIP定制": "VIP 맞춤 설정",
	"本会员解释权归本公司所有": "본 회원 해석 권한은 회사에 있습니다",
	"VIP会员尊享A": "VIP 회원 혜택 A",
	"会员尊享介绍": "회원 혜택 소개",
	"可享受退款": "환불 가능",
	"游伴随机匹配": "랜덤 동행 매칭",

	"VIP会员尊享B": "VIP 회원 혜택 B",
	"VIP会员尊享C": "VIP 회원 혜택 C",
	"VIP_B_INFO": "요구에 따라 동행자를 매칭할 수 있습니다<br>현지 엘리트 파티를 즐길 수 있습니다",
	"VIP_C_INFO": "요구에 따라 동행자를 매칭할 수 있습니다<br>명예파티 및 비즈니스 엘리트 파티 입장권, 한정된 할당량의 하이엔드 비즈니스 미팅",



	"about":{
		"fya1": "서비스 범위는 아시아, 유럽, 오세아니아, 북극 및 남극을 포함한 7개 대륙의 수백 개 목적지를 포함하고 있습니다.",
		"fya2": "주요 사업",
		"fya3":"글로벌 고급 개인 맞춤화",
		"fya4":"기업 팀 빌딩 출장",
		"fya5":"요트 클럽 빌라 파티",
		"fya6": "고급 자동차 렌트",
		"fya7": "개인 제트기",
		"fya8":"기본 경로 + SVIP 사용자 정의",
		"fya9": "고급 새 게임플레이 + 궁극의 서비스",
		"fyb1": "주요 기능과 하이라이트는 목적지의 새로운 고급 게임플레이와 최고의 서비스입니다.",
		"fyb2":"글로벌 고급 개인 맞춤화",
		"fyb3": "Hand In Hand는 고객을 위해 맞춤형 여행을 제공하며, 현지 지원을 통한 수제 여행 일정, 개인 가이드, 개인 차량을 제공합니다. 회사의 하이엔드 여행 상품에는 모험 여행, 맞춤 여행, 가족 여행, 동행 여행, 허니문 여행, 소형 보트 여행 등이 포함됩니다. Hand In Hand의 전 세계적으로 확장된 여행 네트워크는 많은 세계적 목적지로 개인화된 고급 여행을 가능하게 하는 주요 장점입니다. 개인 맞춤형 여행, 당신만의 여행 방식을 맞춤 설정하기.",
		"fyb4":"핵심 이점",
		"fyb5": "Hand In Hand VS 동료",
		"fyb6":"제품 기능",
		"fyb7": "일류 티켓 판매원, 직접 호텔 리소스, 참신하고 흥미로운 목적지 게임 플레이, 맞춤 설정을 통한 일대일 경로 계획 설계",
		"fyb8": "항공권과 호텔에 대한 대행사가 많고 제품 솔루션이 심각하게 균질화되어 있습니다.",
		"fyb9":"서비스 방법",
		"fyc1":"과정 전반에 걸쳐 24시간 여행 집사 추적, 고객 요구 또는 문제에 대한 원스톱 솔루션",
		"fyc2": "문제가 발생하면 아무도 찾을 수 없으며 해결책이 포괄적이고 시의적절하지 않습니다.",
		"fyc3": "서비스 범위",
		"fyc4": "개인 섬과 무인지대를 포함하여 교통 수단으로 접근할 수 있는 모든 장소를 맞춤 설정할 수 있습니다.",
		"fyc5": "제한된 목적지 이용 가능",
		"fyc6":"행&鲁",
		"fyc7":"태국/사이판/캄보디아 및 기타 글로벌 목적지의 최고 항공기(롤스로이스, 벤틀리, 캐딜락 'Army One...) 광동, 홍콩 및 마카오 국경을 넘나드는 직접 자체 운항 함대(도요타 알파 시리즈) )" ,
		"fyc8":"라인(&E)",
		"fyc9":"개인용 제트기/헬리콥터/비즈니스 제트기(예: ACS Air Global/Supreme Air) 전 세계 목적지를 위한 일류 항공권 대행사(비즈니스 클래스, 일등석, 이코노미 클래스)",
		"fyd1":"행&해",
		"fyd2":"태국/사이판/선전 및 기타 전 세계 목적지로 가는 고급 요트 남극 글로벌 크루즈 항로",
		"fyd3":"엔터테인먼트 및 클럽",
		"fyd4":"Bohua Palace LUCKYKTVOFSAIPAN (회사 고정 자산)",
		"fyd5": "엔터테인먼트 및 놀이",
		"fyd6": "세계의 인기 목적지를 맞춤화하고 태국/사이판 및 기타 장소에서 모든 바다, 육지 및 항공 자원을 이용할 수 있는 새로운 방법을 만듭니다. 세계 최고의 호텔/스파/골프 클럽/슈퍼카와의 심층적인 협력 클럽",
		"fyd7": "소울메이트 멤버십",
		"fyd8":"뛰어난 멤버십 시스템을 만들고, 당신의 정체성을 업그레이드하고, 당신의 지위를 부각시키세요. 보왕 멤버십 카드(계좌 개설에 필요한 한도액 포함)를 소지한 VIP는 여행 상품 예약, 탑 바 등 스타 등급 혜택을 누릴 수 있습니다. , 럭셔리 자동차와 요트, 최고급 스파 클럽하우스, 세계적인 수준의 식사 즐거움, 명품 브랜드 쇼핑 할인 등을 제공하며, 24시간 전문팀이 올데이 서비스, 실시간 사후관리, 맞춤형 서비스를 제공합니다. 틀을 뛰어넘어 무한한 가능성을 창조해 보세요.",
		"fyd9":"회사 소개",

		"fye1":"Hand In Hand는 등록 자본금 5천만 달러를 보유한 글로벌 고급 여행 서비스 제공업체입니다. 고품질의 여행 꿈을 추구하는 사람들을 위해 독특하고 매우 즐거운 가벼운 럭셔리 경험을 만드는 데 집중하세요! <br><br>설립 이후 당사의 서비스 범위는 아시아, 유럽, 오세아니아, 북극 및 남극 등 전 세계 7개 대륙 수백 개의 목적지를 포괄하고 있으며, 태국, 사이판 기타 지역에는 자체 브랜드 빌라, 차량, 지상 픽업 서비스가 있으며, 목적지의 최고 수준의 경험이 풍부한 관광 서비스 제공업체와 직접 협력합니다. <br><br>풍부한 하이엔드 서비스 경험과 광범위한 직접 자원 개발 채널을 통해 하이엔드 고객과 사회 엘리트를 위한 프라이빗 맞춤형 서비스를 제공하고, 누구에게도 없고 나도 없는 궁극의 서비스 시스템 여행자원 공급, 특산품 연구개발 등 고품격 종합 서비스 기획을 제공하며, 엄선된 다양한 최고의 여행 일정과 맞춤형 여행 서비스를 제공합니다.",
		"fye2": "이 문단은 우리에 대한 소개입니다.",
		"fye3":"Hand In Hand Co., Ltd.는 선전에 본사를 두고 있으며 등록 자본금은 5천만 달러입니다. 이는 글로벌 고급 여행 서비스 제공업체입니다. 독특하고 매우 즐거운 가벼운 럭셔리 경험을 창조하는 데 중점을 두고 있습니다. 고품격 여행의 꿈을 추구하는 사람들!설립이래 아시아, 유럽, 오세아니아, 북극, 남극 등 전 세계 7개 대륙 수백여 곳의 여행지를 서비스하며, 크고 전문적인 기반을 꾸준히 구축해 왔습니다. 현지 핸들링 팀, 태국, 사이판 및 기타 지역에 자체 브랜드 빌라 보유 차량, 지상 운송 및 목적지의 일류 및 경험이 풍부한 관광 서비스 제공 업체와의 직접적인 협력 풍부한 고급 서비스 경험, 광범위한 우선 - 누구에게도 없고 나에게도 없는 손재주 개발 채널과 최고의 서비스 시스템, 하이엔드 고객/사회 엘리트를 위한 프라이빗 맞춤형 서비스 제공, 기업에 창의적인 팀빌딩 커스터마이징 제공, 고품질 여행자원 공급, 특산품 연구 개발 등을 통해 고품격 올인클루시브 서비스 기획을 제공하고, 엄선된 다양한 최고의 여행 일정과 맞춤형 여행 서비스를 제공합니다.",
		"fye4":"",
	},

	"qt":{
		"fya1":"수수료 설명",
		"fya2": "수수료 포함",
		"fya3":"자동차 요금:",
		"fya4":"비공개 고객 서비스:",
		"fya5":"티켓 수수료:",
		"fya6": "수수료는 포함되지 않습니다",
		"fya7":"비자:",
		"fya8": "비자 수수료는 포함되어 있지 않으며 여행/방문 비자 대행 서비스가 제공될 수 있습니다.",
		"fya9":"티켓:",
		"fyb1":"항공권 수수료 전액이 포함되어 있지 않으며, 국제선/국내선 항공권 예약 서비스를 제공할 수 있습니다.",
		"fyb2":"호텔:",
		"fyb3": "전체 호텔 숙박 비용은 포함되지 않습니다.",
		"fyb4": "초과근무 수당:",
		"fyb5":"별도의 공항 이동을 제외한 운전기사의 정규 근무 시간은 하루 10시간(09:00~19:00)입니다. 제한 시간이 제한 시간을 초과하는 경우 초과 근무 요금을 미리 지불해 주시기 바랍니다.",
		"fyb6":"자체 자금 조달 프로젝트:",
		"fyb7": "일정에 포함된 옵션 활동, 티켓 및 식사는 견적에 포함되지 않습니다.",
		"fyb8": "개인 잡비:",
		"fyb9":"호텔 내 식음료, 세탁비, 전화비, 담배 및 주류, 유료 TV, 인터넷 요금, 팩스, 수하물 취급 수수료 등 개인 비용, 이로 인해 발생하는 개인 소비 및 팁을 포함합니다." ,
		"fyc1":"추가 수수료:",
		"fyc2": "교통 지연, 파업, 날씨, 항공기 또는 기계 고장, 항공편 취소 또는 시간 변경과 같은 불가항력 사유로 인한 추가 비용",
		"fyc3":"기타 수수료:",
		"fyc4": "여기에 언급되지 않은 다른 모든 항목",
		"fyc5": "예약 지침",
		"fyc6":"예약 지침",
		"fyc7":"1. 여행자의 신체상태가 여행상품의 여정에 적합한지 확인해야 합니다.",
		"fyc8":"2. 예약 오류로 인해 여행에 영향을 미치는 일이 없도록 예약 시 정확하고 완전한 정보(성별, 국적, 연락처, 성인인지 여부 등 포함)를 제공하시기 바랍니다. 잘못된 개인정보 제공으로 인한 경제적 손실은 본인이 부담해야 합니다.",
		"fyc9":"3. 당사 대행사가 귀하를 대신하여 비자를 신청해야 하는 경우, 예약 주문 확인 후 즉시 비자에 필요한 자료를 준비해야 하며, 비자 자료가 진본이고 완전한지 확인해야 합니다. 소속사에서 정한 최종 마감일 전에 우리 클럽으로 오세요.",
		"fyd1": "4. 여행 중에는 우리 나라와 목적지 국가 또는 지역의 관련 규정 및 현지 공공 장소의 관련 질서 규칙을 준수하고, 현지의 종교적 신념, 민족적 관습 및 현지 상황을 존중하고, 현지의 자연 환경을 자각하여 보호해야 합니다. 국가(지역)의 관련 규정을 위반하고 과다한 통화, 물품 또는 금지품을 소지한 경우 모든 결과는 본인이 부담하여야 합니다.",
		"fyd2":"5. 본 여행 상품의 내용은 비수기 및 성수기의 변화에 ​​따라 자주 조정됩니다. 상품 소개, 여행 일정, 가격 구조 및 기타 관련 정보는 참고용일 뿐입니다. 최종 상품 내용은 다음과 같습니다. 양측 모두 확인.",
		"fyd3":"6. 고객님께서 합의된 일정에 맞춰 여행을 하지 못하시거나, 고객님의 사정으로 여행상품의 일부 품목을 사용하지 않으신 경우, 해당 여행상품의 사용을 포기하신 것으로 간주됩니다. 귀하의 것이며 저희 에이전시에서는 어떠한 수수료도 환불하지 않습니다.",
		"fyd4":"7. 불가항력으로 인해 여행 수행 또는 계속이 불가능한 경우 당사는 귀하의 동의를 얻은 후 해당 여행 내용을 변경할 수 있습니다. 긴급한 상황으로 인해 조언을 구하는 것이 불가능하거나 귀하의 동의를 얻을 수 없는 경우 당사는 조언을 구한 후 동의를 받은 경우, 내용 변경을 결정할 수 있으나, 그 결정에 필요한 증빙을 제공해야 하며, 이로 인해 발생한 귀하의 경제적 손실에 대해 보험회사에 손해배상을 청구할 수 있도록 도와드리겠습니다.",
		"fyd5":"8. 출입국 관리소, 각국 영사관, 항공사, 보험사 등 당사의 책임이 없는 사유로 귀하 또는 여행자의 개인 또는 재산권이 훼손된 경우 단, 항공편 지연 또는 취소, 교통 통제, 여권 연장, 비자 거부 또는 정시 비자 발급 실패, 입국 및 출국 제한 등에 국한되지는 않습니다. 당사는 능력 한도 내에서 적극적으로 지원하고 귀하의 어려움을 줄이기 위해 노력할 것입니다. 손실 및 기타 문제는 귀하의 협상을 통해 해결됩니다.",
		"fyd6":"9. 귀하의 권리를 보호하기 위해 여행 상품 관련 서비스가 필요하거나 여행 중에 여행에 어려움이 있는 경우 24시간 보호 핫라인 로 전화하여 상담 및 도움을 받으실 수 있습니다. ",
		"fyd7": "취소 및 변경 규칙",
		"fyd8":"1. 상품에 포함된 품목은 고객의 요구에 따라 맞춤 제작되므로, 취소 및 변경 규정은 실제 여정 확인 양식을 참고하시기 바랍니다.",
		"fyd9":"2. 여행 상품에 포함된 국제선 구간 항공권은 항공권 확인 양식에 따른 환불 및 변경 규정이 적용됩니다.",
	}

}
