export default {

	"HOME":"HOME",
	"COMBO":"COMBO",
	"VIP":"VIP",
	"TRAELING":"TRAELING",
	"ABOUT":"ABOUT",

	"ban": "Insufficient balance.",
	"联系我们": "Contact us",
	"简体中文": "Simplified Chinese",

	"游泳池": "Swimming pool",
	"热水浴池": "Hot tub",
	"退款额高": "High refund amount",
	"匹配VIP伴游": "Match VIP companion",

	"行程时间": "Itinerary time",
	"出行人数": "Number of travelers",
	"旅行总花费": "Total travel cost",
	"定制同款行程": "Customize the same itinerary",
	"detailsInfo": "When you are feeling lost, tired, or sad, take a trip, because you can see a different world. Maybe you can be like me, enjoying the food and scenery of the journey, and also meet a unique love. When our eyes met, we talked about many interesting experiences and shared various foods and scenery. This encounter brought us closer to each other. He understands music, can paint, and has a gentle and kind heart. We experienced local culture together, tasted local food, and felt the peaceful and beautiful life of nature. We spent a pleasant journey together. Later, we kept in touch because we both missed that wonderful encounter and had a wonderful feeling for each other. Life must be impulsive once, for someone, for love, for travel, or for dreams.",
	"定制成功": "Customized successfully",

	"热门定制套餐": "Popular customized packages",
	"Ta们的旅行故事": "Their travel stories",
	"达人旅游攻略": "Expert travel tips",
	"全球高端私人定制": "Global high-end private customization",
	"企业团建商旅": "Corporate team building business travel",
	"游艇俱乐部": "Yacht club",
	"别墅轰趴": "Villa party",
	"豪车租赁": "Luxury car rental",
	"私人飞机": "Private jet",

	"私人定制": "Private customization",

	"VIP定制": "VIP customization",
	"本会员解释权归本公司所有": "The interpretation of this membership belongs to the company.",
	"VIP会员尊享A": "VIP member privilege A",
	"会员尊享介绍": "Member privilege introduction",
	"可享受退款": "Can enjoy refund",
	"游伴随机匹配": "Randomly matched companions",
	"VIP会员尊享B": "VIP member privilege B",
	"VIP会员尊享C": "VIP member privilege C",
	"VIP_B_INFO": "Can be matched with companions according to requirements <br> Can enjoy local elite patty during the trip",
	"VIP_C_INFO": "Can be matched with companions according to requirements <br> Admission tickets for socialite party and businessman elite Patty, limited quota high-end business meeting",

	"about":{
		"fya1": "Service coverage includes hundreds of destinations across all seven continents, including Asia, Europe, Oceania, and the North and South Poles.",

		"fya2":"Main business",
		"fya3":"Global high-end private customization",
		"fya4":"Corporate Team Building Business Travel",
		"fya5":"Yacht Club Villa Party",
		"fya6":"Luxury car rental",
		"fya7":"Private jet",
		"fya8":"Classic route + SVIP customization",
		"fya9": "Destination high-end new gameplay + ultimate service",
		"fyb1": "The main features and highlights are the destination's high-end new gameplay and ultimate service",
		"fyb2":"Global high-end private customization",
		"fyb3": "Hand In Hand offers tailor-made travel for customers, providing on-site supported handcrafted itineraries, private guides, and private cars. The company's high-end travel products include adventure tours, customized tours, family tours, accompanied tours, honeymoon tours, small boat tours, and more. The extensive travel network around the world is a major advantage for Hand In Hand, allowing for personalized luxury travel to numerous global destinations. Private custom travel, customizing your own way of traveling.",
		"fyb4":"Core Advantage",
		"fyb5":"Hand in hand VS Same industry",
		"fyb6":"Product Features",
		"fyb7": "First-class ticket agent, first-hand hotel resources, novel and interesting destination gameplay, one-to-one design of route plan by customizer",
		"fyb8":"There are many agents for air tickets and hotels, and the product solutions are seriously homogenized",
		"fyb9":"Service method",
		"fyc1":"24-hour travel butler tracking throughout the process, one-stop solution to customer needs or problems",
		"fyc2": "No one can be found when the problem arises, and the solution is not comprehensive and timely",
		"fyc3":"Service scope",
		"fyc4":"Any place accessible by transportation can be customized, including private islands and no-man's land",
		"fyc5":"Limited destinations available",
		"fyc6":"line&land",
		"fyc7":"Top fleets in Thailand/Saipan/Cambodia and other destinations around the world (Rolls-Royce, Bentley, Cadillac 'Army One...) Cross-border self-operated fleets from Guangdong, Hong Kong and Macao (Toyota Alpha Series)",
		"fyc8":"line&empty",
		"fyc9":"Private jet/helicopter/business jet (such as ACS Air Global/Supreme Air) First-class air ticket agent for global destinations (business class, first class, economy class)",
		"fyd1":"line&ocean",
		"fyd2":"Luxury yacht Antarctic global cruise route to Thailand/Saipan/Shenzhen and other global destinations",
		"fyd3":"Entertainment & Club",
		"fyd4":"Bohua Palace LUCKYKTVOFSAIPAN (Company fixed assets)",
		"fyd5":"Entertainment&Play",
		"fyd6": "Create a new way to customize the world's popular destinations & have all the sea, land and air resources available in Thailand/Saipan and other places. In-depth cooperation with the world's top hotels/spas/golf clubs/supercar clubs",
		"fyd7":"Hand In Hand Membership",
		"fyd8":"Create a distinguished membership system, upgrade your identity, and highlight your status. VIPs holding a Hand In Hand membership card (with a financial threshold for opening an account) can enjoy exclusive star-level benefits covering travel product bookings, top bars, luxury cars and yachts, and top spas Clubhouse, world-class dining enjoyment, luxury brand shopping discounts, etc., and a 24-hour professional team to provide all-day service, real-time follow-up, and tailor-made travel itineraries. Go beyond the framework and create unlimited possibilities.",
		"fyd9":"Company introduction",
		"fye1": "Hand In Hand, with a registered capital of 50 million, is a global high-end travel service provider. It focuses on creating unique and ultimate luxury experiences for those who pursue high-quality travel dreams!<br><br>Since its establishment, our service coverage spans hundreds of destinations across all seven continents, including Asia, Europe, Oceania, and the North and South Poles. We have established a large and professional ground handling team in these destinations, and we have our own branded villas, fleets, and ground handling services in locations such as Thailand and Saipan. We also have direct partnerships with top-tier and experienced local travel service providers.<br><br>With rich experience in high-end services and extensive first-hand resource development channels, we provide personalized services for high-end clients and social elites, as well as creative customized team building services for companies, high-quality travel resource supply, and unique product development. We offer comprehensive high-quality service planning and tailored travel services.",
		"fye2":"This paragraph is an introduction about us",
		"fye3":"Hand In Hand Co., Ltd. is headquartered in Shenzhen, with a registered capital of 50 million. It is a global high-end travel service provider. It focuses on creating a unique and extremely enjoyable light luxury experience for those who pursue high-quality travel dreams! Since its establishment , our service scope covers hundreds of destinations in seven continents around the world, including Asia, Europe, Oceania, and the North and South Pole. We have successively established a large and professional ground handling team locally, and have our own brand villas in Thailand, Saipan and other places. The fleet, ground transportation, and direct cooperation with the first-class and experienced tourism service providers in the destination. Rich high-end service experience, extensive first-hand resource development channels, and an ultimate service system that no one has and I have, for high-end Customers/social elites provide private customized services, provide companies with creative team building customization, high-quality travel resource supply, specialty product research and development, etc., provide all-inclusive high-quality service planning, and provide various selected top travel itineraries and tailor-made travel services.",
		"fye4":"",
	},

	"qt":{
		"fya1":"Fees Description",
		"fya2":"Fees included",
		"fya3":"Car fee:",
		"fya4":"Private customer service:",
		"fya5":"Ticket fee:",
		"fya6":"Fee does not include",
		"fya7":"Visa:",
		"fya8":"Visa fee is not included, and travel/visiting visa agency services can be provided",
		"fya9":"Ticket:",
		"fyb1":"Full air ticket fee is not included, international/domestic air ticket booking service can be provided",
		"fyb2":"Hotel:",
		"fyb3":"Not including full hotel accommodation costs",
		"fyb4":"Overtime fee:",
		"fyb5":"Except for separate airport transfers, the driver's regular working hours are 10 hours a day (09:00-19:00). If the time limit exceeds the time limit, please pay the overtime fee in advance.",
		"fyb6":"Self-funded projects:",
		"fyb7":"Optional activities at your own expense in the itinerary, tickets and meals not included in the quotation",
		"fyb8":"Personal miscellaneous expenses:",
		"fyb9":"Including personal expenses such as food and beverages, laundry fees, telephone fees, tobacco and alcohol, pay TV, Internet fees, faxes, luggage handling fees, etc. in the hotel, personal consumption and tips caused by it",
		"fyc1":"Additional fees:",
		"fyc2":"Additional expenses caused by force majeure reasons such as traffic delays, strikes, weather, aircraft or machine failures, flight cancellations or time changes",
		"fyc3":"Other fees:",
		"fyc4":"All other items not mentioned in",
		"fyc5":"Booking Instructions",
		"fyc6":"Booking Instructions",
		"fyc7":"1. You should ensure that the traveler's physical condition is suitable for the itinerary of the travel product.",
		"fyc8":"2. Please be sure to provide accurate and complete information when booking (including gender, nationality, contact information, whether you are an adult or a child, etc.) to avoid booking errors and affecting your trip. If you provide incorrect personal information The economic losses caused must be borne by you.",
		"fyc9":"3. If you need our agency to apply for a visa on your behalf, you must prepare the materials required for the visa immediately after confirming the reservation order, and be sure to ensure that the visa materials are authentic and complete before the final deadline given by our agency. Come to our club.",
		"fyd1": "4. During your travels, you should follow the relevant regulations of our country and the destination country or region, as well as the relevant order rules of local public places, respect the local religious beliefs, ethnic customs, and local conditions, and consciously protect the local natural environment. If you violate the relevant regulations of the country (region), carry excessive currency, goods, or prohibited items, you shall bear all consequences on your own.",
		"fyd2":"5. The content of this travel product is adjusted frequently with changes in the off-peak and peak seasons. Product introduction, itinerary, price structure and other related information are for reference only. The final product content is subject to confirmation by both parties.",
		"fyd3":"6. If you fail to travel on time according to the agreed itinerary or if some of the items in the travel product are not used due to your own reasons, it will be deemed that you have given up using it on your own, and our agency will not refund any fees.",
		"fyd4":"7. If it is impossible to perform or continue the trip due to force majeure, our company can change the corresponding travel content after obtaining your consent. When it is impossible to seek advice due to urgent circumstances or cannot obtain your consent after seeking advice, Our company can decide to change the content, but we should provide necessary proof for the decision made. Our company will assist you in claiming compensation from the insurance company for your resulting economic losses.",
		"fyd5":"8. If the personal or property rights and interests of you or the traveler are damaged due to reasons that are not attributable to our company, such as the immigration administration, consulates of various countries, airlines, insurance companies and other authorized institutions, including However, it is not limited to flight delays or cancellations, traffic control, passport extensions, visa rejections or failure to issue visas on time, entry and exit restrictions, etc. You can call our agency in time, and our agency will actively assist within its capabilities and try to reduce your troubles. Loss and other matters shall be resolved through your own negotiation.",
		"fyd6":"9. In order to protect your rights, you can call our 24-hour protection hotline for consultation and help if you have any needs for services in travel products or if you have travel difficulties during your trip. ",
		"fyd7":"Cancellation and change rules",
		"fyd8":"1. Since the items included in the product are customized according to customer needs, please refer to the actual itinerary confirmation form for the cancellation and change rules.",
		"fyd9":"2. International segment air tickets included in travel products shall be subject to the refund and change rules in accordance with the air ticket confirmation form.",
	}

}
